import axios from "axios";
import CryptoJS from "crypto-js";
import toast from "react-hot-toast";
const baseURL =
  process.env.REACT_APP_NODE_ENV === "production_node_environment"
    ? process.env.REACT_APP_PRODUCTION_URL
    : process.env.REACT_APP_BACKEND_BASE_LOCAL_URL;

const Base_Url = axios.create({
  baseURL: `${baseURL}/`,
});

Base_Url.interceptors.request.use(function (config) {
  let token = localStorage.getItem("token");
  console.log(token, "token");
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

Base_Url.interceptors.response.use(
  (response) => {
    // Decrypt the response data
    const decryptedData = decryptResponseData(response.data);

    // Log the decrypted data to the console
    console.log("Decrypted Data:", decryptedData);

    return response;
  },
  (error) => {
    // Handle errors
    switch (error?.response?.status) {
      case 400:
        toast.error("something went wrong")
        break;
      case 500:
        toast.error("Internal Server Error")
        break;
      default:
        toast("Loading...")
    }

    return Promise.reject(error);
  }
);

// Function to decrypt response data
const decryptResponseData = (encryptedData) => {
  // Perform decryption using CryptoJS or any other encryption library
  // For example, if using AES encryption:
  const bytes = CryptoJS.AES.decrypt(encryptedData, "encryptionKey");
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

  return decryptedData;
};

export default Base_Url;
