import { Stack, Typography, Button ,IconButton} from "@mui/material";
import sq1 from "../../images/sq1.svg";
import sq2 from "../../images/sq2.svg";
import connect from "../../images/icons8-connector-100@2x.png";
import Connections from "../../common/connectionData/connnectionData";

const SetConnections = ({
  popupRef,
  backgroundColor,
  // connections,
  setSelectedConnection,
  selectedConnection,
}) => {

  return (
    <Stack
      ref={popupRef}
      bgcolor={
        backgroundColor === "moonlight"
          ? "rgb(155 155 155 / 51%)"
          : "rgba(255, 255, 255, 0.5)"
      }
      ml={"47px"}
      width={"42.82%"}
      p={"30px 20px 25px 30px"}
      borderLeft={"2px solid hsl(210deg 33% 96% / 20%)"}
      borderTop={"2px solid hsl(210deg 33% 96% / 20%)"}
      borderRadius={"5px 0px 0px 5px"}
    >
      <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
        <Stack className="incht" bgcolor={"rgba(98, 134, 106, 0.75)"}>
          <img alt="icon" height={"25px"} width={"25px"} src={connect} />
        </Stack>
        <Typography
          sx={{
            font: "normal normal normal 20px/25px Averia Serif Libre",
          }}
        >
          Create a Connection
        </Typography>
      </Stack>

      <Stack
        direction={"row"}
        mt={"30px"}
        mb={"10px"}
        justifyContent={"space-between"}
      >
        <Typography
          textAlign={"justify"}
          sx={{
            font: "normal normal normal 20px/25px Averia Serif Libre",
          }}
          // color={backgroundColor === "moonlight" ? "#ffffff" : ""}
        >
          Available Connections
        </Typography>
      </Stack>
      <Stack borderTop={"1px solid #000000"}></Stack>
      <Typography
        mt={"10px"}
        textAlign={"left"}
        sx={{ font: "italic normal normal 15px/19px Averia Serif Libre" }}
      >
        Pick an application, then follow the on screen prompts to create an
        intelligent connection to BEBA
      </Typography>
      <Stack mt={"30px"} gap={"10px"} flexWrap={"wrap"} direction={"row"}>

        {Connections?.map((content, i) => (
          <Stack
            key={i}
            width={"43%"}
            direction={"row"}
            bgcolor={"rgba(255, 255, 255, 0.25)"}
            border={"1px solid rgba(0, 0, 0, 0.25)"}
            borderRadius={"5px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            p={"6px 10px"}
          >
            <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
              <img
                alt="icon"
                src={`${content?.icon}`}
                width={"23px"}
                height={"23px"}
              />
              <Typography
                className="fo_15_norm"
                color={backgroundColor === "moonlight" && "#fff"}
              >
                {content?.name}
              </Typography>
            </Stack>
            <IconButton onClick={() => setSelectedConnection(content?.name)}>
              <img
                src={selectedConnection === content?.name ? sq2 : sq1}
                style={{ opacity: "0.7" }}
                alt="icon"
                width={"16px"}
                height={"16px"}
              />
            </IconButton>
          </Stack>
        ))}
        
      </Stack>
    </Stack>
  );
};

export default SetConnections;