import {
  BACK_TO,
  DETECT_FILE_TYPE,
  GET_ALL_CHATS,
  GET_ALL_FOLDERS,
  GET_ALL_FOLDER_CHATS,
  GET_ALL_MEMORIES,
  NEW_SESSION_OF_CHAT,
  SETUP,
  SET_AUTO_CHAT_DATA,
  SET_CHAT_DATA,
  SET_INPUT_LANGUAGE,
  SET_LOADER,FORCE_LOAD
} from "./actiontypes";

const initialState = {
  chatMessage: "",
  language: null,
  fileType: null,
  autochatdata: [],
  newChatArray: [],
  loader: true,
  allFolders: [],
  allChat: [],
  allMemories: [],
  fromMain: "",
  allFolderChat: [],
  loadChat : true,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT_DATA:
      return {
        ...state,
        chatMessage: action.payload,
      };

    case SET_INPUT_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case DETECT_FILE_TYPE:
      return {
        ...state,
        fileType: action.payload,
      };

    case SET_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case FORCE_LOAD:
      return {
        ...state,
        loadChat: action.payload,
      };

    case GET_ALL_FOLDERS:
      return {
        ...state,
        allFolders: action.payload,
        // allFolders: [...state?.allFolders, ...action?.payload],
      };

    case GET_ALL_CHATS:
      return {
        ...state,
        allChat: action.payload,
      };

    case GET_ALL_FOLDER_CHATS:
      return {
        ...state,
        allFolderChat: action.payload,
      };

    case GET_ALL_MEMORIES:
      return {
        ...state,
        allMemories: action.payload,
        // allMemories: [...state?.allMemories, ...action?.payload],
      };

    case SET_AUTO_CHAT_DATA:
      return {
        ...state,
        autochatdata: action.payload,
        newChatArray: [...state?.newChatArray, action.payload],
        loader: false,
      };

    case BACK_TO:
      return {
        ...state,
        autochatdata: [],
        fromMain: "",
      };

    case NEW_SESSION_OF_CHAT:
      return {
        ...state,
        autochatdata: action.payload,
        loader: false,
      };

    case SETUP:
      return {
        ...state,
        fromMain: action.payload,
        loader: false,
      };

    default:
      return state;
  }
};

export default chatReducer;
