import { Stack, Button, Typography, Skeleton, Menu } from "@mui/material";
import backicon from "../../images/icons8-arrow-100-6@2x.png";
import indx from "../../images/icons8-index-64-2@2x.png";
import connect from "../../images/icons8-connector-100@2x.png";
import btlogo from "../../images/Screenshot 2024-04-03M@2x.png";
import bLogo from "../../images/Bearish OS Logo_Orange@2x.png";
import { useDispatch, useSelector } from "react-redux";
import docu from "../../images/icons8-document-50@2x.png";
import pdf from "../../images/icons8-pdf-96@2x.png";
import db from "../../images/icons8-database-100@2x.png";
import outlook from "../../images/icons8-outlook-96@2x.png";
import goglecal from "../../images/icons8-google-calendar-96@2x.png";
import sales from "../../images/icons8-salesforce-96@2x.png";
import goglemeet from "../../images/icons8-google-meet-96@2x.png";
import gogledrv from "../../images/icons8-drive-96@2x.png";
import box from "../../images/icons8-box-96@2x.png";
import slack from "../../images/icons8-slack-96@2x.png";
import adove from "../../images/icons8-adobe-creative-cloud-96@2x.png";
import insta from "../../images/icons8-instagram-96@2x.png";
import xtweet from "../../images/icons8-twitterx-90@2x.png";
import bebx from "../../images/icons8-webex-96@2x.png";
import zoom from "../../images/icons8-zoom-96@2x.png";
import tktok from "../../images/icons8-tik-tok-100@2x.png";
import teams from "../../images/icons8-microsoft-teams-2019-96@2x.png";
import dropbx from "../../images/icons8-dropbox-96@2x.png";
import fb from "../../images/icons8-facebook-100@2x.png";
import newoutlook from "../../images/icons8-outlook-calendar-96@2x.png";
import hub from "../../images/icons8-hubspot-a-developer-and-marketer-of-software-products-24@2x.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import HoveredMenu from "../../components/hoveredMenu";
import Memory from "../../components/chats/memory";
import SetConnections from "../../components/chats/connection";
import AutoNomous from "../../components/chats/autoNomous";
import UserActionsPopup from "../../common/leftside/userActionsPopup";
import ChatInput from "../../common/chatinput/chatInput";
import {
  handleBackbtn,
  forceLoad,
} from "../../redux/actions/chatActions/chatActions";
import { useParams } from "react-router-dom";
import Base_Url from "../../api/base_Url";
import Uaim from "./common/userAIMessage/usn";

const memoryData = [
  { icon: "", contentName: "Image PNG Memory Item", id: "1" },
  { icon: "", contentName: "Any other image file Memory Item", id: "2" },
  {
    icon: docu,
    contentName: "Any Type of Document, Excel or Powerpoint",
    id: "3",
  },
  { icon: pdf, contentName: "Any PDF for Memory Item", id: "4" },
  { icon: db, contentName: "Any Database that is Connected", id: "5" },
];

const connectData = [
  { icon: outlook, name: "Outlook", id: "1" },
  { icon: goglecal, name: "Calendar", id: "2" },
  { icon: sales, name: "Salesforce", id: "3" },
  { icon: goglemeet, name: "Google Meet", id: "4" },
  { icon: gogledrv, name: "Google Drive", id: "5" },
  { icon: box, name: "Box", id: "6" },
  { icon: slack, name: "Slack", id: "7" },
  { icon: adove, name: "Creative Cloud", id: "8" },
  { icon: insta, name: "Instagram", id: "9" },
  { icon: xtweet, name: "X", id: "10" },
  { icon: bebx, name: "Webex", id: "11" },
  { icon: zoom, name: "Zoom", id: "12" },
  { icon: fb, name: "Facebook", id: "16" },
  { icon: dropbx, name: "Dropbox", id: "17" },
  { icon: newoutlook, name: "Outlook", id: "18" },
  { icon: tktok, name: "Tik Tok", id: "14" },
  { icon: teams, name: "Teams", id: "11" },
  { icon: hub, name: "Hubstaff", id: "9" },
];

const InChat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { backgroundColor, userNameOrEmail, loggedData } = useSelector(
    (state) => state.authReducer
  );
  const {
    chatMessage,
    autochatdata,
    newChatArray,
    fromMain,
    loader,
    allMemories,
    loadChat,
  } = useSelector((state) => state.chatReducer);

  const { folderId, chatId } = useParams();

  const popupRef = useRef(null);
  const [chatsArray, setChatArray] = useState([]);

  const [openMenu, setOpenMenu] = useState(null);
  const [loding, setLoding] = useState(true);
  const [userQuestion, setUserQuestion] = useState("");
  const [anchorEl, setAnchorEl] = useState("");
  const [rightSidebar, setRightSidebar] = useState("");
  const [selectedConnection, setSelectedConnection] = useState("");
  const [userName, setUserName] = useState(sessionStorage.getItem("name"));
  const [bebaAction, setBebaAction] = useState("");
  const [bebaDecision, setBebaDecision] = useState("");
  const [audioBuffer, setAudioBuffer] = useState(null);
  useEffect(() => {
    const keyData = {
      userID: loggedData?.user,
      expirationDate: new Date(),
      usageLimit: 4,
      modelName: "user",
    };
    // dispatch(generateKey(keyData));
  }, []);

  useEffect(() => {
    if (fromMain === "main") {
      // If fromMain is "main", start a new chat
      if (
        autochatdata &&
        autochatdata.userQuestion &&
        autochatdata.autoAnswer
      ) {
        setChatArray([
          {
            userQuestion: autochatdata.userQuestion,
            autoAnswer: autochatdata.autoAnswer,
          },
        ]);
      }
    } else if (fromMain === "inchat") {
      // If fromMain is "inchat", add autochatdata to the previous chat data
      if (
        autochatdata &&
        autochatdata.userQuestion &&
        autochatdata.autoAnswer
      ) {
        // Check if autochatdata already exists in chatsArray
        const exists = chatsArray.some(
          (chat) =>
            chat.userQuestion === autochatdata.userQuestion &&
            chat.autoAnswer === autochatdata.autoAnswer
        );

        // If autochatdata doesn't exist, add it to chatsArray
        if (!exists) {
          setChatArray((prevChatArray) => [
            ...prevChatArray,
            {
              userQuestion: autochatdata.userQuestion,
              autoAnswer: autochatdata.autoAnswer,
            },
          ]);
        }
      }
    }
    setUserQuestion(chatMessage);
  }, [fromMain, autochatdata, chatMessage]);

  const [ca, sca] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setRightSidebar("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [loadingIndices, setLoadingIndices] = useState([]);
  const [audioLoadedIndices, setAudioLoadedIndices] = useState([]);
  const handleChangeInAudio = async (data, index) => {
    console.log(data, "itemitemitem")
    setLoadingIndices((prevLoadingIndices) => [...prevLoadingIndices, index]);
    // const autoAnswerText = data.autoAnswer;
    // console.log(autoAnswerText);
    // let modifiedAutoAnswer = autoAnswerText?.replace(/\\n\d*/g, "");
    // modifiedAutoAnswer = modifiedAutoAnswer?.replace(/[{}"]/g, "");
    // const startIndex = modifiedAutoAnswer?.indexOf("Response:");
    // const modifiedAutoAnswers = modifiedAutoAnswer
    //   ?.substring(startIndex + "Response:"?.length)
    //   ?.trim();
    // modifiedAutoAnswers?.trim();
    // console.log(modifiedAutoAnswers, "modifiedAutoAnswer");
    // let modifiedAutoAnswersString = modifiedAutoAnswers?.toString();
    const payload = new FormData();
    payload.append("text", data?.content);
    payload.append("VoiceSpeaker", 1);
    payload.append("language", "en");

    // for lingo model
    try {
      const res = await Base_Url.post(`m/lm`, payload);
      if (res?.status === 200 && res?.data?.lingoResponse?.task_id) {
        const taskId = res.data.lingoResponse.task_id;
        setAudioLoadedIndices((prevAudioLoadedIndices) => [
          ...prevAudioLoadedIndices,
          index,
        ]);
        setTimeout(() => fetchLingoResponse(taskId), 5000);
      }
    } catch (error) {
      console.error("Error initializing audio processing:", error);
    }
  };

  const fetchLingoResponse = async (taskId) => {
    try {
      const response = await Base_Url.get(`m/lpm/${taskId}`, {
        responseType: "arraybuffer",
      });
      console.log(response, "response");
      if (response.status === 200) {
        const audioBlob = new Blob([response.data], { type: "audio/mpeg" });
        setAudioBuffer(URL.createObjectURL(audioBlob));
      } else if (response.status === 202) {
        // Assuming 202 means processing
        console.log("Processing... will retry in 3 seconds");
        setTimeout(() => fetchLingoResponse(taskId), 3000); // Retry after 5 seconds
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching audio:", error);
      const audioBlob = new Blob([error.data], { type: "audio/mpeg" });
      setAudioBuffer(URL.createObjectURL(audioBlob));
    }
  };

  useEffect(() => {
    if (AudioBuffer) {
      playAudio();
    }
  }, [audioBuffer]);

  const handleBack = () => {
    dispatch(handleBackbtn());
    navigate("/chat");
  };

  const playAudio = () => {
    if (audioBuffer) {
      console.log("Audio buffer available:", audioBuffer);
      const audio = new Audio(audioBuffer);
      audio.play();
    } else {
      console.error("Audio buffer is not available.");
    }
  };

  console.log(chatsArray, "chatsArray");

  const handleLoadChats = async (i) => {
    try {
      const response = await Base_Url.get(
        `/c/folders/${folderId}/chats/${chatId}/messages?offset=${i ? i : 0}`
      );
      if (response.status === 200) {
        sca(response?.data?.messages);
      }
      dispatch(forceLoad(false));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleLoadChats();
  }, [folderId, chatId]);
  useEffect(() => {
    // dispatch(forceLoad(true));
    if (loadChat) {
      handleLoadChats();
    }
  }, [loadChat]);

  return (
    <Stack
      direction={"row"}
      bgcolor={
        backgroundColor === "moonlight"
          ? "rgba(0, 0, 0, 0.8)"
          : "rgba(98, 134, 106, 0.75)"
      }
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        pl={rightSidebar === "" && "15px"}
        height={"768px"}
        width={"100%"}
      >
        <Button
          onClick={() => handleBack()}
          sx={{
            p: "0px",
            gap: "5px",
            mt: "20px",
            height: "20px",
            minWidth: rightSidebar !== "" && "53px",
          }}
        >
          <img src={backicon} alt="5" width={"20px"} height={"20px"} />
          {rightSidebar === "" && (
            <Typography
              sx={{ font: "normal normal normal 15px/17px Averia Serif Libre" }}
              color={"#fff"}
            >
              Back
            </Typography>
          )}
        </Button>
        <Stack
          width={rightSidebar !== "" ? "54.90%" : "76.86%"}
          maxWidth={rightSidebar !== "" ? "54.90%" : "76.86%"}
          minWidth={rightSidebar !== "" ? "54.90%" : "76.86%"}
        >
          <Stack
            maxHeight={"603px"}
            pt={"39px"}
            overflow={"scroll"}
            pl={"25px"}
            textAlign={"left"}
            sx={{
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            boxShadow={
              backgroundColor === "moonlight" && "0px 2px 6px #00000029"
            }
            bgcolor={
              backgroundColor === "moonlight"
                ? "#222222"
                : "rgba(255, 255, 255, 0.65)"
            }
            border={
              backgroundColor === "moonlight"
                ? ""
                : "5px solid rgba(242, 245, 248, 0.65)"
            }
          >
            {ca?.map((item, index) => (
              <Stack key={index}>
                <Uaim
                  item={item}
                  loding={loding}
                  userQuestion={userQuestion}
                  handleChangeInAudio={handleChangeInAudio}
                  index={index}
                  loadingIndices={loadingIndices}
                  playAudio={playAudio}
                  audioLoadedIndices={audioLoadedIndices}
                />
              </Stack>
            ))}
            {loader && (
              <>
                <Stack direction={"row"} gap={"10px"} pr={"27px"} mb={"28px"}>
                  <Stack
                    width={"25px"}
                    bgcolor={"rgba(98, 134, 106, 0.75)"}
                    color={"#fff"}
                    height={"25px"}
                    borderRadius={"25px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      sx={{
                        font: "normal normal normal 15px/17px Averia Serif Libre",
                      }}
                    >
                      {loggedData?.name?.charAt(0)}
                    </Typography>
                  </Stack>
                  <Stack gap={"16px"} maxWidth={"98%"} minWidth={"94%"}>
                    <Typography
                      color={backgroundColor === "moonlight" && "#ffffff"}
                      className="fo_15_norm"
                    >
                      {loggedData?.name ? loggedData?.name : ""}
                    </Typography>
                    <Typography
                      color={backgroundColor === "moonlight" && "#ffffff"}
                      className="fo_15_norm"
                      style={{ maxWidth: "97%", minWidth: "97%" }}
                    >
                      {userQuestion
                        ? userQuestion
                        : "I’m writing a paper about the future of AI in Universities and I’m hoping to help people better understand? Do you have any research papers that can help with this?"}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={"10px"}
                  width={"97%"}
                  pr={"27px"}
                  mb={"28px"}
                >
                  <img src={bLogo} alt="" width={"25px"} height={"21px"} />
                  <Stack gap={"16px"} width={"100%"}>
                    <Typography
                      className="fo_15_norm"
                      color={backgroundColor === "moonlight" && "#ffffff"}
                    >
                      BEBA
                    </Typography>
                    <Stack width="100%" pr={"27px"}>
                      <Stack>
                        <Skeleton
                          sx={{
                            background:
                              backgroundColor === "moonlight"
                                ? "rgba(142, 142, 142, 0.7)"
                                : "rgba(20, 82, 32, 0.7)",
                          }}
                          width={"96%"}
                          height={20}
                        />
                        <Skeleton
                          sx={{
                            background:
                              backgroundColor === "moonlight"
                                ? "rgba(142, 142, 142, 0.6)"
                                : "rgba(20, 82, 32, 0.6)",
                          }}
                          width={"83%"}
                          height={20}
                        />
                        <Skeleton
                          sx={{
                            background:
                              backgroundColor === "moonlight"
                                ? "rgba(142, 142, 142, 0.55)"
                                : "rgba(20, 82, 32, 0.55)",
                          }}
                          width={"74%"}
                          height={20}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
          <Typography
            sx={{ font: "normal normal normal 15px/17px Averia Serif Libre" }}
            textAlign={"left"}
            mt={"10px"}
            color={backgroundColor === "moonlight" && "#ffffff"}
          >
            BEBA has limited access to the internet, always check his responses
            to ensure they are accurate
          </Typography>
          <Stack mt={"50px"}>
            <ChatInput
              folderIds={folderId}
              chatIds={chatId}
              bgcolr={"rgba(255, 255, 255, 0.65)"}
              from={"auto"}
            />
          </Stack>
        </Stack>

        {rightSidebar === "" && (
          <Stack pr={"50px"} pt={"43px"} alignItems={"center"}>
            <Stack
              className="cur_po"
              bgcolor={backgroundColor === "moonlight" ? "#000000" : "#62866A"}
              borderRadius={"50px"}
              width={"50px"}
              height={"50px"}
              border={
                backgroundColor === "moonlight" ? "none" : "1px solid #FFFFFF"
              }
              justifyContent={"center"}
              color={"#fff"}
              onClick={(e) => setOpenMenu(e.currentTarget)}
            >
              <Typography
                sx={{
                  font: "normal normal normal 20px/25px Averia Serif Libre",
                }}
              >
                {userName?.charAt(0)}
              </Typography>
            </Stack>
            <Menu
              open={openMenu}
              anchorEl={openMenu}
              PaperProps={{
                style: {
                  width: "250px",
                  height: "260px",
                  paddingTop: "0px",
                },
              }}
              onClose={() => setOpenMenu(null)}
            >
              <UserActionsPopup />
            </Menu>
            <Stack
              className={"incht cur_po"}
              onClick={() => setRightSidebar("memory")}
              bgcolor={backgroundColor === "moonlight" ? "#000000" : "#62866A"}
              mt={"30px"}
              boxShadow={backgroundColor === "moonlight" && "none"}
              onMouseEnter={() => setAnchorEl("memory")}
              onMouseLeave={() => setAnchorEl("")}
            >
              <img alt="icon" width={"25px"} height={"25px"} src={indx} />
              {anchorEl === "memory" && (
                <HoveredMenu
                  infoName={"Memory"}
                  top="127px"
                  right="105px"
                  bg="#EBEBEB"
                  anchorEl={anchorEl}
                />
              )}
            </Stack>
            <Stack
              className="incht cur_po"
              onClick={() => setRightSidebar("connections")}
              bgcolor={backgroundColor === "moonlight" ? "#000000" : "#62866A"}
              boxShadow={backgroundColor === "moonlight" && "none"}
              mt={"30px"}
              onMouseEnter={() => setAnchorEl("connections")}
              onMouseLeave={() => setAnchorEl("")}
            >
              <img alt="icon" width={"25px"} height={"25px"} src={connect} />
              {anchorEl === "connections" && (
                <HoveredMenu
                  infoName={"Connections"}
                  top="193px"
                  right="105px"
                  bg="#EBEBEB"
                  anchorEl={anchorEl}
                />
              )}
            </Stack>
            <Stack
              onClick={() => setRightSidebar("auto")}
              height={"40px"}
              width={"40px"}
              mt={"25px"}
              onMouseEnter={() => setAnchorEl("auto")}
              onMouseLeave={() => setAnchorEl("")}
              className="cur_po"
            >
              <img alt="icon" src={btlogo} />
              {anchorEl === "auto" && (
                <HoveredMenu
                  infoName={"Auto"}
                  top="256px"
                  right="105px"
                  bg="#EBEBEB"
                  anchorEl={anchorEl}
                />
              )}
            </Stack>
          </Stack>
        )}

        {rightSidebar === "memory" && (
          <Memory
            popupRef={popupRef}
            backgroundColor={backgroundColor}
            memoryData={memoryData}
            connectData={connectData}
            Id={loggedData?.user}
          />
        )}

        {rightSidebar === "connections" && (
          <SetConnections
            popupRef={popupRef}
            backgroundColor={backgroundColor}
            // connections={connections}
            setSelectedConnection={setSelectedConnection}
            selectedConnection={selectedConnection}
          />
        )}

        {rightSidebar === "auto" && (
          <AutoNomous
            popupRef={popupRef}
            setBebaAction={setBebaAction}
            setBebaDecision={setBebaDecision}
            bebaDecision={bebaDecision}
            bebaAction={bebaAction}
            backgroundColor={backgroundColor}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default InChat;
