import { Button, Stack, Typography } from "@mui/material";
import BottomLogo from "../../common/leftside/bottomLogo";
import { useSelector } from "react-redux";

const AfterRegister = () => {

  const { userNameOrEmail } = useSelector((state) => state.authReducer);

  return (
    <Stack
      className="left_box"
      bgcolor={"rgba(98, 134, 106, 0.6)"}
      alignItems={"center"}
    >
      <Stack
        sx={{
          background: "#62866A",
          boxShadow: "0px 2px 6px #00000029",
          border: "2px solid #F2F5F8",
          borderRadius: "5px",
          opacity: "1",
        }}
        p={"45px 20px 36px"}
        mt={"227px"}
      >
        <Typography
          sx={{ font: " normal normal normal 40px/49px Averia Serif Libre" }}
          color={"#fff"}
        >
          Welcome to BEBA
        </Typography>
        <Typography
          sx={{ font: "normal normal normal 20px/25px Averia Serif Libre" }}
          color={"#fff"}
          textAlign={"justify"}
          mt={"25px"}
          mb={"20px"}
        >
          What should I call you?
        </Typography>
        <Stack gap={"5px"}>
          <Typography
            sx={{ font: "normal normal 300 15px/17px Averia Serif Libre" }}
            textAlign={"justify"}
            color={"#fff"}
          >
            Full Name
          </Typography>
          <Stack
            width={"411px"}
            p={"7px 10px"}
            textAlign={"justify"}
            color={"#fff"}
            sx={{
              border: "2px solid #F2F5F8",
              font: "normal normal 300 15px/17px Averia Serif Libre",
              borderRadius: "5px",
            }}
          >
            {userNameOrEmail}
          </Stack>
        </Stack>
        <Stack alignItems={"center"}>
          <Button
            sx={{
              mt: "20px",
              width: "225px",
              border: "2px solid #F2F5F8",
              borderRadius: "5px",
              color: "#fff",
              p: "4px 38px",
            }}
          >
            Get Started Now
          </Button>
        </Stack>
      </Stack>
      <BottomLogo margintop={"161px"} />
    </Stack>
  );
};

export default AfterRegister;
