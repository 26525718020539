
import bLogo from "../../images/Bearish OS Logo_Orange@2x.png";
import outlook from "../../images/icons8-outlook-96@2x.png";
import goglecal from "../../images/icons8-google-calendar-96@2x.png";
import sales from "../../images/icons8-salesforce-96@2x.png";
import goglemeet from "../../images/icons8-google-meet-96@2x.png";
import gogledrv from "../../images/icons8-drive-96@2x.png";
import box from "../../images/icons8-box-96@2x.png";
import slack from "../../images/icons8-slack-96@2x.png";
import adove from "../../images/icons8-adobe-creative-cloud-96@2x.png";
import insta from "../../images/icons8-instagram-96@2x.png";
import xtweet from "../../images/icons8-twitterx-90@2x.png";
import bebx from "../../images/icons8-webex-96@2x.png";
import zoom from "../../images/icons8-zoom-96@2x.png";
import tktok from "../../images/icons8-tik-tok-100@2x.png";
import teams from "../../images/icons8-microsoft-teams-2019-96@2x.png";
import dropbx from "../../images/icons8-dropbox-96@2x.png";
import fb from "../../images/icons8-facebook-100@2x.png";
import newoutlook from "../../images/icons8-outlook-calendar-96@2x.png";
import hub from "../../images/icons8-hubspot-a-developer-and-marketer-of-software-products-24@2x.png";


const Connections = [
    { icon: outlook, name: "Outlook", id: "1" },
    { icon: goglecal, name: "Calendar", id: "2" },
    { icon: goglemeet, name: "Google Meet", id: "3" },
    { icon: sales, name: "Salesforce", id: "4" },
    { icon: slack, name: "Slack", id: "5" },
    { icon: box, name: "Box", id: "6" },
    { icon: xtweet, name: "X", id: "7" },
    { icon: adove, name: "Creative Cloud", id: "8" },
    { icon: hub, name: "Hubstaff", id: "9" },
    { icon: insta, name: "Instagram", id: "10" },
    { icon: teams, name: "Teams", id: "11" },
    { icon: bebx, name: "Webex", id: "12" },
    { icon: gogledrv, name: "Google Drive", id: "13" },
    { icon: tktok, name: "Tik Tok", id: "14" },
    { icon: zoom, name: "Zoom", id: "15" },
    { icon: fb, name: "Facebook", id: "16" },
    { icon: dropbx, name: "Dropbox", id: "17" },
    { icon: newoutlook, name: "Outlook", id: "18" },
    { icon: bLogo, name: "Request App", id: "19" },
  ];
  

  export default Connections