import { CircularProgress, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import sound from "../../../../images/icons8-audio-100@2x.png";
import stop from "../../../../images/icons8-stop-64@2x.png";
import bLogo from "../../../../images/Bearish OS Logo_Orange@2x.png";
import ReactMarkdown from "react-markdown";
const Uaim = ({
  item,
  loding,
  userQuestion,
  handleChangeInAudio,
  index,
  loadingIndices,
  playAudio,
  audioLoadedIndices,
}) => {
  const { backgroundColor, loggedData } = useSelector(
    (state) => state.authReducer
  );

  function DynamicMarkdownTypography({
    loading,
    content,
    userQuestion,
    backgroundColor,
  }) {
    const markdownContent = loading ? content : userQuestion;

    return (
      <Typography
        color={backgroundColor === "moonlight" ? "#ffffff" : undefined}
        className="fo_15_norm"
        style={{ maxWidth: "97%", minWidth: "97%" }}
      >
        <ReactMarkdown>{markdownContent}</ReactMarkdown>
      </Typography>
    );
  }

  return (
    <div>
      {item?.role === "user" && (
        <Stack direction={"row"} gap={"10px"} pr={"27px"} mb={"28px"}>
          <Stack
            width={"25px"}
            bgcolor={"rgba(98, 134, 106, 0.75)"}
            color={"#fff"}
            height={"25px"}
            borderRadius={"25px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{
                font: "normal normal normal 15px/17px Averia Serif Libre",
              }}
            >
              {loggedData?.name?.charAt(0)}
            </Typography>
          </Stack>
          <Stack gap={"16px"} pt={"3px"} maxWidth={"98%"} minWidth={"94%"}>
            <Typography
              color={backgroundColor === "moonlight" && "#ffffff"}
              className="fo_15_norm"
            >
              {loggedData?.name ? loggedData?.name : ""}
            </Typography>
            <Typography
              color={backgroundColor === "moonlight" ? "#ffffff" : undefined}
              className="fo_15_norm"
              style={{ maxWidth: "97%", minWidth: "97%" }}
            >
              <ReactMarkdown>
                {loding ? item?.content : userQuestion}
              </ReactMarkdown>
            </Typography>
          </Stack>
        </Stack>
      )}
      {item?.role !== "user" && (
        <Stack
          direction={"row"}
          gap={"10px"}
          width={"97%"}
          pr={"27px"}
          mb={"28px"}
        >
          <Stack width={"25px"} height={"25px"}>
            <img src={bLogo} alt="" />
          </Stack>
          <Stack gap={"16px"} pt={"5px"} width={"100%"}>
            <Stack direction={"row"} gap={"10px"}>
              <Typography
                className="fo_15_norm"
                color={backgroundColor === "moonlight" && "#ffffff"}
              >
                BEBA
              </Typography>
              <Stack
                className="cur_po"
                onClick={() => handleChangeInAudio(item, index)}
              >
                <img alt="icon" width={"15px"} height={"15px"} src={sound} />
              </Stack>
              {loadingIndices?.includes(index) ? (
                <Stack
                  className="cur_po"
                  ml={"15px"}
                  width={"15px"}
                  height={"15px"}
                >
                  <CircularProgress size={16} style={{ color: "white" }} />
                </Stack>
              ) : (
                // Show the stop image only when both APIs are successfully called
                audioLoadedIndices.includes(index) && (
                  <Stack
                    ml={"15px"}
                    className="cur_po"
                    onClick={() => playAudio()}
                  >
                    <img alt="icon" width={"15px"} height={"15px"} src={stop} />
                  </Stack>
                )
              )}
            </Stack>

            <Stack width="100%" pr={"27px"}>
              {!loding ? (
                <Stack>
                  <Skeleton
                    sx={{
                      background:
                        backgroundColor === "moonlight"
                          ? "rgba(142, 142, 142, 0.7)"
                          : "rgba(20, 82, 32, 0.7)",
                    }}
                    width={"96%"}
                    height={20}
                  />
                  <Skeleton
                    sx={{
                      background:
                        backgroundColor === "moonlight"
                          ? "rgba(142, 142, 142, 0.6)"
                          : "rgba(20, 82, 32, 0.6)",
                    }}
                    width={"83%"}
                    height={20}
                  />
                  <Skeleton
                    sx={{
                      background:
                        backgroundColor === "moonlight"
                          ? "rgba(142, 142, 142, 0.55)"
                          : "rgba(20, 82, 32, 0.55)",
                    }}
                    width={"74%"}
                    height={20}
                  />
                </Stack>
              ) : (
                <>
                  <Typography
                    color={backgroundColor === "moonlight" && "#ffffff"}
                    className="fo_15_norm"
                    style={{ maxWidth: "97%", minWidth: "97%" }}
                  >
                    <ReactMarkdown>
                      {loding ? item?.content : userQuestion}
                    </ReactMarkdown>
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </div>
  );
};

export default Uaim;
