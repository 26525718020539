import { Stack } from "@mui/material";

const HoveredMenu = ({ infoName, anchorEl, top, right,bg }) => {
  
  return (
    <Stack
      position="absolute"
      bgcolor={bg}
      p={"4px 15px"}
      top={top}
      right={right}
      borderRadius={"5px"}
      sx={{
        opacity: anchorEl ? 1 : 0,
        pointerEvents: anchorEl ? "auto" : "none",
        transition: "opacity 0.3s",
        font: "normal normal bold 15px/21px Averia Serif Libre",
        boxShadow: "0px 3px 6px #00000029",
      }}
    >
      {infoName}
    </Stack>
  );
};

export default HoveredMenu;
