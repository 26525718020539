import { Button, Stack, Typography } from "@mui/material";
import texticon from "../../images/documenticon/icons8-code-64@2x.png";
import modelicon from "../../images/documenticon/icons8-3d-model-100@2x.png";
import apiicon from "../../images/documenticon/icons8-key-80@2x.png";
import storegeicon from "../../images/documenticon/icons8-storage-100@2x.png";
import usageicon from "../../images/documenticon/icons8-storage-80@2x.png";
import setttingicon from "../../images/documenticon/icons8-setting-80@2x.png";
import btlogo from "../../images/Screenshot 2024-04-03M@2x.png";

const SideBarOption = () => {
  const options = [
    { icon: texticon, value: "Test", id: "1" },
    { icon: modelicon, value: "Models", id: "2" },
    { icon: apiicon, value: "API Key", id: "3" },
    { icon: storegeicon, value: "Storage", id: "4" },
    { icon: usageicon, value: "Usage", id: "5" },
    { icon: setttingicon, value: "Settings", id: "6" },
  ];

  return (
    <Stack>
      <Stack
        height={"60px"}
        width={"60px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack
          borderBottom={"1px solid #fff"}
          height={"60px"}
          justifyContent={"center"}
        >
          <img src={btlogo} height={"30px"} width={"30px"} />
        </Stack>
      </Stack>
      <Stack mt={"20px"} gap={"20px"} alignItems={"center"}>
        {options?.map((content, index) => (
          <Button
            sx={{
              p: "0px",
              flexDirection: "column",
              color: "#fff !important",
              gap: "5px",
              textTransform:"none"
            }}
            key={index}
          >
            <img
              src={`${content?.icon}`}
              alt="icon"
              height={"20px"}
              width={"20px"}
            />
            <Typography
              sx={{ font: "normal normal 300 11px/13px Averia Serif Libre" }}
            >
              {content?.value}
            </Typography>
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};

export default SideBarOption;
