import { Button, Stack, Typography } from "@mui/material";
import serch from "../../images/documenticon/icons8-search-100-2@2x.png";
import { useState } from "react";
const quickStart = [
  { name: "Overview", id: "1" },
  { name: "Introduction", id: "2" },
  { name: "Models", id: "3" },
  { name: "Guides", id: "4" },
  { name: "Changelogs & Updates", id: "5" },
];
const Features = [
  { name: "BEBA System", id: "6" },
  { name: "Intelligent Connections", id: "7" },
  { name: "Blockchain Security", id: "8" },
  { name: "Visual Builder", id: "9" },
  { name: "Burnout Mental Health Models", id: "10" },
  { name: "Unlimited Memory", id: "12" },
  { name: "Up Next", id: "13" },
  { name: "Models", id: "14" },
];
const guides = [
  { name: "10 Lines of Code", id: "15" },
  { name: "Production Standards", id: "16" },
  { name: "Guardrails", id: "17" },
  { name: "Latency Optimization", id: "18" },
  { name: "Open Source", id: "19" },
  { name: "Libraries & SDKs", id: "20" },
  { name: "Policies", id: "21" },
];
const playground = [
  { name: "BEBA in Action", id: "22" },
  { name: "Models", id: "23" },
];
const SideBar = () => {
  const [serchedValue, setSearchedValue] = useState("");
  return (
    <Stack
      borderRight={"1px solid #fff"}
      minWidth={"257px"}
      paddingLeft={"15px"}
    >
      <Stack mb={"15px"} mt={"27px"}>
        <Stack
          width={"220px"}
          border="1px solid #2E3033"
          borderRadius="5px"
          direction={"row"}
          p={"10px 12px"}
          bgcolor={"#000000"}
          gap={"5px"}
        >
          <img src={serch} height={"15px"} width={"15px"} />
          <input
            placeholder="Search"
            style={{
              border: "none",
              outline: "none",
              background: "none",
              color: "#fff",
            }}
            value={serchedValue}
            onChange={(e) => setSearchedValue(e.target.value)}
          />
        </Stack>
      </Stack>
      <Stack
        height={"626px"}
        overflow={"scroll"}
        sx={{
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Typography
          className="f_16_b"
          width={"140px"}
          borderBottom={"2px solid #2E3033"}
          paddingBottom={"6px"}
        >
          Quick Start Guide
        </Typography>
        <Stack gap={"15px"} mt={"10px"} mb={"20px"}>
          {quickStart?.map((content, index) => (
            <Button
              key={index}
              sx={{
                p: "0px",
                textTransform: "none",
                color: "#fff",
                justifyContent: "flex-start",
              }}
            >
              <Typography className="f_16">{content?.name}</Typography>{" "}
            </Button>
          ))}
        </Stack>
        <Typography
          className="f_16_b"
          width={"140px"}
          borderBottom={"2px solid #2E3033"}
          paddingBottom={"6px"}
        >
          Features
        </Typography>
        <Stack gap={"15px"} mb={"20px"} mt={"10px"}>
          {Features?.map((content, index) => (
            <Button
              key={index}
              sx={{
                p: "0px",
                textTransform: "none",
                color: "#fff",
                justifyContent: "flex-start",
              }}
            >
              <Typography className="f_16">{content?.name}</Typography>{" "}
            </Button>
          ))}
        </Stack>
        <Typography
          className="f_16_b"
          width={"140px"}
          borderBottom={"2px solid #2E3033"}
          paddingBottom={"6px"}
        >
          Guides
        </Typography>
        <Stack gap={"15px"} mb={"20px"} mt={"10px"}>
          {guides?.map((content, index) => (
            <Button
              key={index}
              sx={{
                p: "0px",
                textTransform: "none",
                color: "#fff",
                justifyContent: "flex-start",
              }}
            >
              <Typography className="f_16">{content?.name}</Typography>{" "}
            </Button>
          ))}
        </Stack>
        <Typography
          className="f_16_b"
          width={"140px"}
          borderBottom={"2px solid #2E3033"}
          paddingBottom={"6px"}
        >
          Playground
        </Typography>
        <Stack gap={"15px"} mb={"20px"} mt={"10px"}>
          {playground?.map((content, index) => (
            <Button
              key={index}
              sx={{
                p: "0px",
                textTransform: "none",
                color: "#fff",
                justifyContent: "flex-start",
              }}
            >
              <Typography className="f_16">{content?.name}</Typography>{" "}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SideBar;
