import { Button, Stack, Typography } from "@mui/material";
import copyicon from "../../images/documenticon/icons8-copy-100@2x.png";
import StartedData from "./startedData/startedData";
import fir from "../../images/documenticon/cardimg/90857477a6db83b5416c3f0ccce6ba79@2x.png";
import sec from "../../images/documenticon/cardimg/4aaa6c59e4057d80bd2ab7b2197dcae8@2x.png";
import third from "../../images/documenticon/cardimg/8aa8850b3379eb83dca2acd5e1cd5935@2x.png";
import fourth from "../../images/documenticon/cardimg/c74b9b5a0f0bfe0b4ea39ac7ae566255@2x.png";
import ModelData from "./startedData/modelData";
import mfir from "../../images/documenticon/cardimg/7c853e0231499ce510f5bbf877b8f043-1@2x.png";
import msec from "../../images/documenticon/cardimg/297eaae774a550e4679311b9d9c91e9c@2x.png";
import mthird from "../../images/documenticon/cardimg/ada56ff32fe5c1b07f6516cdacfe34ca@2x.png";
import mfourth from "../../images/documenticon/cardimg/8e8ef75a286106636088c0be927f9e36@2x.png";
import Connections from "../../common/connectionData/connnectionData";
import IntelligentConnect from "./startedData/conenct";
import { useState } from "react";

const cardData = [
  {
    backgrond: fir,
    heading: "Your first AI app with BEBA",
    id: "1",
    content: "10 Lines of Code",
  },
  {
    backgrond: sec,
    heading: "BEBA Infrastructure Chart",
    id: "2",
    content: "Turn on/off models",
  },
  {
    backgrond: third,
    heading: "Prebuilt Agents",
    id: "3",
    content: "Use pre-built managed by Bearish Agents",
  },
  {
    backgrond: fourth,
    heading: "Intelligent Connections",
    id: "4",
    content: "Connect to every app with ease",
  },
];

const modelData = [
  {
    backgrond: mfir,
    heading: "BEBA",
    id: "1",
    content:
      "A multi-modal multi-model system with visual, audio, speech, language, security, mental health, and more all built in…",
  },
  {
    backgrond: msec,
    heading: "OpenAI",
    id: "2",
    content:
      "Use our API keys and build with OpenAI and the BEBA infrastructure",
  },
  {
    backgrond: mthird,
    heading: "Claude",
    id: "3",
    content:
      "Use our APIkeys and build with Claude Models and the BEBA infrastructure",
  },
  {
    backgrond: mfourth,
    heading: "Cohere",
    id: "4",
    content:
      "Use our API keys and build with Cohere’s Models and the BEBA infrastructure",
  },
];

const RightSideBar = () => {
  const [selectedConnection, setSelectedConnection] = useState("");

  return (
    <Stack>
      <Stack pt={"25px"} pl={"20px"}>
        <Stack textAlign={"left"} gap={"10px"}>
          <Typography className="f_25">
            Welcome to BEBA, an Infrastructure to build better AI
          </Typography>
          <Typography className="f_16" pr={"100px"}>
            Connect an entire AI system, multiple models, endless memory,
            intelligent connections to all the apps you love with just 10 lines
            of code and your API key.
          </Typography>
        </Stack>
        <Stack textAlign={"left"} mt={"40px"} gap={"10px"}>
          <Typography className="fo_15_norm" color={"#fff"}>
            Your API Key
          </Typography>
          <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
            <Stack
              width={"500px"}
              bgcolor={"#000000"}
              border="2px solid #2E3033"
              borderRadius="5px"
              p={"17px 13px"}
            >
              HNICSIA86JA653^%jdfnalnKDAfNsaudj314fjdas3210jvfakls4859
            </Stack>
            <Button sx={{ p: "0px", minWidth: "0px" }}>
              <img src={copyicon} width={"15px"} height={"15px"} alt="icon" />
            </Button>
          </Stack>

          <Button sx={{p:"0px" ,mt:"10px"}}>
            <Typography
              textAlign={"center"}
              className="fo_15_norm"
              color={"#fff"}
              pr={"47px"}
            >
              Go to all keys
            </Typography>
          </Button>
        </Stack>
        <Stack height={"450px"} overflow={"scroll"}>
          <Stack mt={"30px"}>
            <Typography textAlign={"left"} className="f_25" mb={"20px"}>
              Get Started
            </Typography>
            <Stack
              direction={"row"}
              gap={"20px"}
              width={"1000px"}
              sx={{
                overflowY: "hidden",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none" /* Internet Explorer 10+ */,
                "scrollbar-width": "none" /* Firefox */,
              }}
            >
              {cardData?.map((data, index) => (
                <StartedData data={data} i={index} />
              ))}
            </Stack>
          </Stack>
          <Stack mt={"30px"}>
            <Typography textAlign={"left"} className="f_25" mb={"20px"}>
              Models
            </Typography>
            <Stack
              direction={"row"}
              gap={"20px"}
              width={"1000px"}
              sx={{
                overflowY: "hidden",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              }}
            >
              {modelData?.map((data, index) => (
                <ModelData data={data} i={index} />
              ))}
            </Stack>
          </Stack>
          <Stack mt={"30px"} pb={"20px"}>
            <Typography textAlign={"left"} className="f_25" mb={"20px"}>
              Intelligent Connections
            </Typography>
            <Stack mt={"30px"} gap={"10px"} flexWrap={"wrap"} direction={"row"}>
              {Connections?.map((content, i) => (
                <IntelligentConnect
                  setSelectedConnection={setSelectedConnection}
                  selectedConnection={selectedConnection}
                  content={content}
                  i={i}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RightSideBar;
