import { Button, Stack, Typography, Menu } from "@mui/material";
import belogo from "../../images/Bearish OS Logo_Orange@2x.png";
import { useNavigate } from "react-router-dom";
import BottomLogo from "../../common/leftside/bottomLogo";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import Tri from "../../components/triDropDown";
import UserActionsPopup from "../../common/leftside/userActionsPopup";
import backicon from "../../images/icons8-arrow-100-6@2x.png";
import folder from "../../images/icons8-folder-100-2@2x.png";
import ChatInput from "../../common/chatinput/chatInput";
import DragAndDropUpload from "../../components/fileUpload/draganddrop";
import { handleBackbtn } from "../../redux/actions/chatActions/chatActions";
import threeDot from "../../images/icons8-three-dots-100@2x.png";
import Base_Url from "../../api/base_Url";
const NewChat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { backgroundColor, loggedData } = useSelector(
    (state) => state?.authReducer
  );
  const { allChats } = useSelector((state) => state.chatReducer);
  const popupRef = useRef(null);

  const [selectedValue, setSelectedValue] = useState("");
  const [opneDropDown, setOpenDropDown] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpenDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = [...e.dataTransfer.files];
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleBack = () => {
    dispatch(handleBackbtn());
    navigate("/chat");
  };

  const handleOpenFolder = async (data, i) => {
    try {
      const response = await Base_Url.get(
        `/c/folders/${data?.folderId}/${loggedData?.user}/chats?offset=${i}`
      );
      console.log(response, "response");
    } catch (error) {
      console.log(error);
    }
  };
console.log(allChats, "allChats")
  return (
    <>
      <Stack
        // height={"768px"}
        width={"100%"}
        className="left_box"
        bgcolor={
          backgroundColor === "moonlight"
            ? "rgba(0, 0, 0, 0.8)"
            : "rgba(98, 134, 106, 0.6)"
        }
        alignItems={"center"}
      >
        <Toaster position="top-center" reverseOrder={false} />
        <Stack
          width={"96%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"end"}
          pr={"50px"}
          pt={"43px"}
        >
          <Button
            onClick={handleBack}
            sx={{
              p: "0px",
              gap: "5px",
              mt: "20px",
              ml: "45px",
              height: "20px",
              minWidth: "53px",
            }}
          >
            <img src={backicon} width={"20px"} height={"20px"} />

            <Typography
              sx={{ font: "normal normal normal 15px/17px Averia Serif Libre" }}
              color={"#fff"}
            >
              Back
            </Typography>
          </Button>
          <Typography
            alignItems={"center"}
            display={"flex"}
            bgcolor={backgroundColor === "moonlight" ? "#000000" : "#62866A"}
            justifyContent={"center"}
            border={backgroundColor === "moonlight" ? "" : "1px solid #FFFFFF"}
            width={"50px"}
            height={"50px"}
            borderRadius={"50px"}
            sx={{ font: "normal normal normal 20px/25px Averia Serif Libre" }}
            color={"#fff"}
            onClick={(e) => setOpenMenu(e.currentTarget)}
          >
            {loggedData?.name?.charAt(0)}
          </Typography>
          <Menu
            open={openMenu}
            anchorEl={openMenu}
            PaperProps={{
              style: {
                width: "250px",
                height: "260px",
                paddingTop: "0px",
              },
            }}
            onClose={() => setOpenMenu(null)}
          >
            <UserActionsPopup />
          </Menu>
        </Stack>
        <Stack direction={"row"} gap={"25px"}>
          <Stack direction="row">
            <img alt="icon" src={belogo} width={"50px"} height={"51px"} />
            <Typography
              sx={{
                font: "normal normal normal 40px/49px Averia Serif Libre",
                color: "#ffffff",
              }}
            >
              BEBA
            </Typography>
          </Stack>
          <Stack>
            <Stack
              position={"relative"}
              // border={opneDropDown ? "2px solid transparent": "2px solid rgba(242, 245, 248, 0.25)"}
              border={"2px solid transparent"}
              borderRadius={opneDropDown ? "20px 20px 0px 0px" : "20px"}
              bgcolor={"rgba(255, 255, 255, 0.25)"}
              direction={"row"}
              gap={"10px"}
              alignItems={"center"}
              padding={"9px 6px 9px 20px"}
              onClick={() => setOpenDropDown(!opneDropDown)}
            >
              <Typography
                sx={{
                  font: "normal normal normal 20px/25px Averia Serif Libre",
                }}
                color={"#fff"}
              >
                model:
              </Typography>
              <Typography
                color={backgroundColor === "moonlight" ? "#fff" : ""}
                sx={{
                  font: "normal normal normal 20px/25px Averia Serif Libre",
                }}
              >
                {selectedValue !== "" ? selectedValue : "Bear Club"}
              </Typography>
              <Stack
                ml={"25px"}
                className={opneDropDown ? "rotate" : ""}
                onClick={() => setOpenDropDown(!opneDropDown)}
              >
                <Stack style={{ transform: "rotate(180deg)" }}>
                  <Tri color="#62866A" />
                </Stack>
              </Stack>
            </Stack>

            {opneDropDown && (
              <Stack
                ref={popupRef}
                position={"absolute"}
                className="openBox"
                width={
                  selectedValue === "Grizzly Bear"
                    ? "275.9px"
                    : selectedValue === "Polar Bear"
                    ? "258.3px"
                    : "251.9px"
                }
              >
                <Stack p="18px 0px 0px 22.34px" textAlign={"left"}>
                  <Typography
                    sx={{
                      font: "normal normal normal 13px/16px Averia Serif Libre",
                    }}
                    color={backgroundColor === "moonlight" ? "#fff" : ""}
                    className="cur_po"
                    onClick={() => {
                      setOpenDropDown(false);
                      setSelectedValue("Bear Club");
                    }}
                  >
                    Bear Club
                  </Typography>
                  <Typography
                    sx={{
                      font: "normal normal normal 10px/12px Averia Serif Libre",
                    }}
                    mt={"3px"}
                    mb={"10px"}
                    color={backgroundColor === "moonlight" ? "#fff" : ""}
                  >
                    Our foundational model good for most tasks
                  </Typography>
                  <Stack
                    direction={"row"}
                    gap={"20.66px"}
                    className="cur_po"
                    alignItems={"center"}
                    onClick={() => {
                      setOpenDropDown(false);
                      setSelectedValue("Polar Bear");
                    }}
                  >
                    <Typography
                      sx={{
                        font: "normal normal normal 13px/16px Averia Serif Libre",
                      }}
                      color={backgroundColor === "moonlight" ? "#fff" : ""}
                    >
                      Polar Bear
                    </Typography>
                    <Typography
                      sx={{
                        font: "normal normal normal 11px/13px Averia Serif Libre",
                      }}
                      color={
                        backgroundColor === "moonlight" ? "#95FFAC" : "#981717"
                      }
                    >
                      Pro Required
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      font: "normal normal normal 10px/12px Averia Serif Libre",
                    }}
                    mt={"3px"}
                    mb={"10px"}
                    color={backgroundColor === "moonlight" ? "#fff" : ""}
                  >
                    A multi-model system for complex tasks
                  </Typography>
                  <Stack
                    direction={"row"}
                    className="cur_po"
                    gap={"9px"}
                    alignItems={"center"}
                    onClick={() => {
                      setOpenDropDown(false);
                      setSelectedValue("Grizzly Bear");
                    }}
                  >
                    <Typography
                      sx={{
                        font: "normal normal normal 13px/16px Averia Serif Libre",
                      }}
                      color={backgroundColor === "moonlight" ? "#fff" : ""}
                    >
                      Grizzly Bear
                    </Typography>
                    <Typography
                      sx={{
                        font: "normal normal normal 11px/13px Averia Serif Libre",
                      }}
                      color={
                        backgroundColor === "moonlight" ? "#95FFAC" : "#981717"
                      }
                    >
                      Pro Required
                    </Typography>
                  </Stack>
                  <Typography
                    mt={"3px"}
                    sx={{
                      font: "normal normal normal 10px/12px Averia Serif Libre",
                    }}
                    color={backgroundColor === "moonlight" ? "#fff" : ""}
                  >
                    Our most powerful 300B parameter model
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack
          height={"406px"}
          mt={"163px"}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {files.length > 0 && (
            <DragAndDropUpload setFiles={setFiles} files={files} />
          )}

          <ChatInput
            setFiles={setFiles}
            files={files}
            bgcolr={"rgba(255, 255, 255, 0.25)"}
            from={"start"}
          />
        </Stack>
        <BottomLogo margintop={"0px"} mb={"15px"} />
      </Stack>

      {/* chat list */}

      {(allChats && allChats?.length !== 0) && (
        <Stack height={"336px"}>
          {allChats?.length !== 0 && (
            <Stack>
              <Stack
                direction={"row"}
                gap={" 10px 25px"}
                height={"270px"}
                overflow={"scroll"}
                sx={{
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
                justifyContent={"center"}
                flexWrap={"wrap"}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: "10px 25px",
                  }}
                >
                  <>
                    {allChats?.map((ele, i) => (
                      <div key={i}>
                        <Stack
                          className="cur_po"
                          bgcolor=" rgba(255, 255, 255, 0.25)"
                          border="1px solid rgba(0, 0, 0, 0.25)"
                          borderRadius="10px"
                          width={"401px"}
                          direction={"row"}
                          p={"18px 15px 18px 30px"}
                          justifyContent={"space-between"}
                          onClick={() => handleOpenFolder(ele)}
                        >
                          <Typography
                            sx={{
                              font: "normal normal normal 20px/25px Averia Serif Libre",
                            }}
                            color={"#ffffff"}
                          >
                            {ele?.chatName ? ele?.chatName : `Chat ${i + 1}`}
                          </Typography>

                          <img
                            alt="icon"
                            height={"25px"}
                            width={"25px"}
                            src={threeDot}
                          />
                        </Stack>
                      </div>
                    ))}
                  </>
                </div>
              </Stack>
            </Stack>
          )}
          <Typography onClick={() => handleOpenFolder(allChats?.length)}>
            Load More
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default NewChat;
