import Base_Url from "../../../api/base_Url";
import {
  LOGIN,
  LOGIN_ERROR,
  SETUSERNAME_EMAIL,
  SET_BACKGROUND_COLOR,
  SET_DOCUMENTATION,
  SET_FOLDER_NAME,
  SET_LOGGED_DATA,
  SET_ROUTES,
} from "../../reducers/authReducer/actiontypes";
import { v4 as uuidv4 } from "uuid";

export const authRegister = (data) => async (dispatch) => {
  try {
    const res = await Base_Url.post(`/registerSyncBearishOS`, data);
    dispatch({
      // type: POST_DATA,
      //   payload: res.data,
    });
  } catch (e) {
    dispatch({
      // type: REQUIREDFIELDS,
      // payload: e.response.data.message,
    });
  }
};

export const authLogin = (record) => async (dispatch) => {
  const requestId = uuidv4();
  try {
    const res = await Base_Url.post(`/login`, record);

    dispatch({
      type: LOGIN,
      //   payload: res.data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: LOGIN_ERROR,
      payload: e.response.data.message,
    });
  }
};

export const userNameAndEmail = (record) => async (dispatch) => {
  const requestId = uuidv4();
  dispatch({
    type: SETUSERNAME_EMAIL,
    payload: record,
  });
};

export const addFolderName = (record) => async (dispatch) => {
  dispatch({
    type: SET_FOLDER_NAME,
    payload: record,
  });
};

export const setBackgroundColor = (record) => async (dispatch) => {
  dispatch({
    type: SET_BACKGROUND_COLOR,
    payload: record,
  });
};

export const loggedData = (record) => async (dispatch) => {
  dispatch({
    type: SET_LOGGED_DATA,
    payload: record,
  });
};

export const activeDocumentation = (record) => async (dispatch) => {
  dispatch({
    type: SET_DOCUMENTATION,
    payload: record,
  });
};

export const forCallingRoutes = (record) => async (dispatch) => {
  dispatch({
    type: SET_ROUTES,
    payload: record,
  });
};

