import { IconButton, Stack, Typography } from "@mui/material";
import sq1 from "../../../images/sq1.svg";
import sq2 from "../../../images/sq2.svg";

const IntelligentConnect = ({
  content,
  i,
  selectedConnection,
  setSelectedConnection,
}) => {
  return (
    <Stack
      key={i}
      width={"22%"}
      direction={"row"}
      bgcolor={"rgba(255, 255, 255, 0.25)"}
      border={"1px solid rgba(0, 0, 0, 0.25)"}
      borderRadius={"5px"}
      alignItems={"center"}
      justifyContent={"space-between"}
      p={"6px 10px"}
      className="cur_po"
      onClick={() => setSelectedConnection(content?.name)}
    >
      <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
        <img
          alt="icon"
          src={`${content?.icon}`}
          width={"23px"}
          height={"23px"}
        />
        <Typography className="fo_15_norm" color={"#fff"}>
          {content?.name}
        </Typography>
      </Stack>
      <IconButton onClick={() => setSelectedConnection(content?.name)}>
        <img
          src={selectedConnection === content?.name ? sq2 : sq1}
          style={{ opacity: "0.7" }}
          alt="icon"
          width={"16px"}
          height={"16px"}
        />
      </IconButton>
    </Stack>
  );
};

export default IntelligentConnect;
