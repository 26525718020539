import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import grp48 from "../../images/Group 4813@2x.png";

const MemoryToUpload = ({
  handleDrop,
  handleUploadaFileInBebaMemory,
  backgroundColor,
  droppedImage,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    setIsDragging(false);
  };

  return (
    <Stack>
      <Stack
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          background: isDragging
            ? "rgba(255, 255, 255, 0.5)"
            : "rgba(255, 255, 255, 0.25)",
          border: "2px dashed rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          marginTop: "30px",
          alignItems: "center",
          gap: "15px",
          justifyContent: "center",
          padding: "30px",
        }}
      >
        {droppedImage ? (
          <img
            src={droppedImage}
            alt="Dropped Image"
            width="172px"
            height="190px"
          />
        ) : (
          <>
            <img src={grp48} width="172px" height="190px" draggable="false" />
            <Typography
              sx={{
                font: "normal normal normal 13px/16px Averia Serif Libre",
              }}
              width="172px"
              color={backgroundColor === "moonlight" ? "#fff" : ""}
            >
              Drag and Drop items here to upload them
            </Typography>
          </>
        )}
      </Stack>
      <Stack marginTop="19px" alignItems="end">
        <Button
          sx={{
            padding: "4px 10px",
            font: "normal normal normal 16px/20px Averia Serif Libre",
            color: "#ffffff",
            background: "#62866A",
            "&:hover": {
              background: "#62866A",
            },
          }}
          onClick={handleUploadaFileInBebaMemory}
        >
          Upload Now
        </Button>
      </Stack>
    </Stack>
  );
};

export default MemoryToUpload;
