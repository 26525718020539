import Base_Url from "../../../api/base_Url";

import {
  BACK_TO,
  DETECT_FILE_TYPE,
  GET_ALL_CHATS,
  GET_ALL_FOLDERS,
  GET_ALL_FOLDER_CHATS,
  GET_ALL_MEMORIES,
  NEW_SESSION_OF_CHAT,
  SETUP,
  SET_AUTO_CHAT_DATA,
  SET_CHAT_DATA,
  SET_INPUT_LANGUAGE,
  SET_LOADER,FORCE_LOAD,
} from "../../reducers/chatreducers/actiontypes";
import { v4 as uuidv4 } from "uuid";

export const chatData = (record) => async (dispatch) => {
  dispatch({
    type: SET_CHAT_DATA,
    payload: record,
  });
};

export const detectLanguage = (record) => async (dispatch) => {
  const requestId = uuidv4();
  dispatch({
    type: SET_INPUT_LANGUAGE,
    payload: record,
  });
};

export const detectFileType = (record) => async (dispatch) => {
  const requestId = uuidv4();
  dispatch({
    type: DETECT_FILE_TYPE,
    payload: record,
  });
};

export const autoChatData = (record) => async (dispatch) => {
  const requestId = uuidv4();

  dispatch({
    type: SET_AUTO_CHAT_DATA,
    payload: record,
  });
};

export const newSessionChat = (record) => async (dispatch) => {
  const requestId = uuidv4();

  dispatch({
    type: NEW_SESSION_OF_CHAT,
    payload: record,
  });
};

export const newSetup = (record) => async (dispatch) => {
  const requestId = uuidv4();
  dispatch({
    type: SETUP,
    payload: record,
  });
};

export const loader = (record) => async (dispatch) => {
  const requestId = uuidv4();

  dispatch({
    type: SET_LOADER,
    payload: record,
  });
};

export const generateKey = (record) => async (dispatch) => {
  const requestId = uuidv4();
  const key = await Base_Url.post(`g/apikey`, record);
  // dispatch({
  //   type: SET_LOADER,
  //   payload:record
  // });
};

export const getAllFolders = (record) => async (dispatch) => {
  dispatch({
    type: GET_ALL_FOLDERS,
    payload: record,
  });
};

export const getAllChats = (record) => async (dispatch) => {
  dispatch({
    type: GET_ALL_CHATS,
    payload: record,
  });
};

export const getAllFolderChats = (record) => async (dispatch) => {
  dispatch({
    type: GET_ALL_FOLDER_CHATS,
    payload: record,
  });
};

export const getAllMemories = (record) => async (dispatch) => {
  dispatch({
    type: GET_ALL_MEMORIES,
    payload: record,
  });
};

export const handleBackbtn = (record) => async (dispatch) => {
  dispatch({
    type: BACK_TO,
    payload: record,
  });
};
export const forceLoad = (record) => async (dispatch) => {
  dispatch({
    type: FORCE_LOAD,
    payload: record,
  });
};
