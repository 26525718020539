import { Stack, Menu, Typography, Button } from "@mui/material";
import info from "../../images/icons8-about-100-2@2x.png";
import sun from "../../images/icons8-sunlight-100@2x.png";
import moon from "../../images/icons8-moon-90@2x.png";
import arrow from "../../images/icons8-arrow-100-5@2x.png";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { setBackgroundColor } from "../../redux/actions/authActions/authActions";
import HoveredMenu from "../../components/hoveredMenu";
import UserActionsPopup from "./userActionsPopup";
const TopSection = ({
  setActiveBackground,
  activeBackground,
  setShowDetails,
  backgroundColor,
  user
}) => {
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState("");
  const [userName, setUserName] = useState(sessionStorage.getItem("name"));

  const handleMoonlight = () => {
    dispatch(setBackgroundColor("moonlight"));
    setActiveBackground(2);
  };

  const handlesunlight = () => {
    dispatch(setBackgroundColor("sunlight"));
    setActiveBackground(1);
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"end"}
      gap={"30px"}
    >
      <Stack
        className="cur_po"
        position="relative"
        width={"45px"}
        height={"35px"}
        bgcolor={"rgba(242, 245, 248, 0.3)"}
        borderRadius={"5px"}
        justifyContent={"center"}
        alignItems={"center"}
        onClick={(e) => setShowDetails("details")}
        onMouseEnter={() => setAnchorEl("info")}
        onMouseLeave={() => setAnchorEl("")}
        sx={{
          transition: "background-color 0.3s",
        }}
      >
        <img alt="icon" height={"25px"} width={"25px"} src={info} />
        {anchorEl === "info" && (
          <HoveredMenu
            infoName={"Info"}
            bg="rgba(235, 235, 235, 0.5)"
            top="40px"
            anchorEl={anchorEl}
          />
        )}
      </Stack>
      <Stack
        width={"80px"}
        height={"35px"}
        direction={"row"}
        bgcolor={"rgba(242, 245, 248, 0.3)"}
        borderRadius={"5px"}
      >
        <Stack
          width={"45px"}
          height={"35px"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            boxShadow:
              backgroundColor === "moonlight" && "0px 6px 6px #00767B29",
          }}
          bgcolor={backgroundColor === "moonlight" && "#000000"}
          borderRadius="5px"
          onClick={handleMoonlight}
        >
          <img alt="icon" height={"25px"} width={"25ppx"} src={moon} />
        </Stack>
        <Stack
          width={"45px"}
          height={"35px"}
          justifyContent={"center"}
          alignItems={"center"}
          bgcolor={backgroundColor !== "moonlight" && "#62866A"}
          borderRadius="5px"
          sx={{
            boxShadow:
              backgroundColor !== "moonlight" && "0px 6px 6px #00767B29",
          }}
          onClick={handlesunlight}
        >
          <img alt="icon" height={"25px"} width={"25ppx"} src={sun} />
        </Stack>
      </Stack>
      <Stack
        height={"50px"}
        width={"50px"}
        bgcolor={
          backgroundColor === "moonlight" ? "#000000" : "#62866A !important"
        }
        sx={{
          border: backgroundColor === "moonlight" ? "" : "1px solid #FFFFFF",

          boxShadow:
            backgroundColor === "moonlight" && "2px 2px 4px rgba(0, 0, 0, 0.2)",

          borderRadius: "50px",
          font: "normal normal normal 20px/25px Averia Serif Libre",
          color: "#fff",
        }}
        justifyContent={"center"}
        onClick={(e) => setOpenMenu(e.currentTarget)}
      >
        {user?.charAt(0)}
      </Stack>
      <Menu
        open={openMenu}
        anchorEl={openMenu}
        PaperProps={{
          style: {
            width: "250px",
            height: "260px",
            paddingTop: "0px",
          },
        }}
        onClose={() => setOpenMenu(null)}
      >
        <UserActionsPopup />
      </Menu>
    </Stack>
  );
};

export default TopSection;
