import { Stack, Typography, Button } from "@mui/material";
import indx from "../../images/icons8-index-64-2@2x.png";
import addico from "../../images/icons8-add-100@2x.png";
import docu from "../../images/icons8-document-50@2x.png";
import pdf from "../../images/icons8-pdf-96@2x.png";
import db from "../../images/icons8-database-100@2x.png";
import AddToMemory from "../../common/memoryandconnection/addToMemory";
import MemoryToUpload from "../../common/memoryandconnection/memorytoupload";
import { useEffect, useState } from "react";
import Base_Url from "../../api/base_Url";
import { getAllMemories } from "../../redux/actions/chatActions/chatActions";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
const Memory = ({ Id, popupRef, backgroundColor, connectData }) => {
  const dispatch = useDispatch();

  const { allMemories } = useSelector((state) => state.chatReducer);
  const [offset, setOffSet] = useState(1);
  const [addMemory, setAddMemory] = useState(false);
  const [droppedImage, setDroppedImage] = useState(null);
  const [droppedFile, setDroppedFile] = useState(null);
  const [memoryData, setMemoryData] = useState(allMemories?.files);
  const handleAddMemory = () => {
    setAddMemory(!addMemory);
  };
  console.log(memoryData, "memoryData");
  const handleUploadedListInBebaMemory = async () => {
    try {
      const uploadedList = await Base_Url.get(
        `c/getUserCollections/${Id}?page=${offset}`
      );
      console.log(uploadedList, "upload");
      if (uploadedList?.status === 200) {
        dispatch(getAllMemories(uploadedList?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleUploadedListInBebaMemory();
  }, []);

  const handleUploadaFileInBebaMemory = async () => {
    if (droppedFile !== null) {
      const formdata = new FormData();
      formdata.append("file", droppedFile);
      formdata.append("uploadType", 1);
      formdata.append("userId", Id);
      try {
        const uploadFile = await Base_Url.post(`c/ucd`, formdata);
        if (uploadFile.status === 200) {
          handleUploadedListInBebaMemory();
          setAddMemory(false);
        }
      } catch (error) {
        console.log(error);
        if (
          error?.response?.data?.message === "Duplicate file upload detected"
        ) {
          toast.error(" You Can Upload Duplicate file ");
        }
      }
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    // setIsDragging(false);
    const file = e.dataTransfer.files[0];
    setDroppedFile(file);
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setDroppedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLoadMore = async () => {
    setOffSet(offset + 1);
    handleUploadedListInBebaMemory();
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />

      <Stack
        ref={popupRef}
        bgcolor={
          backgroundColor === "moonlight"
            ? "rgb(155 155 155 / 51%)"
            : "rgba(255, 255, 255, 0.5)"
        }
        ml={"47px"}
        width={"42.82%"}
        p={"30px 20px 25px 30px"}
      >
        <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
          <Stack className="incht" bgcolor={"rgba(98, 134, 106, 0.75)"}>
            <img alt="icon" height={"25px"} width={"25px"} src={indx} />
          </Stack>
          <Typography
            sx={{
              font: "normal normal normal 20px/25px Averia Serif Libre",
            }}
          >
            BEBA Memory
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          mt={"30px"}
          mb={"10px"}
          justifyContent={"space-between"}
        >
          <Typography
            textAlign={"justify"}
            sx={{
              font: "normal normal normal 20px/25px Averia Serif Libre",
            }}
            color={backgroundColor === "moonlight" ? "#ffffff" : ""}
          >
            All Memory
          </Typography>
          <AddToMemory
            backgroundColor={backgroundColor}
            handleAddMemory={handleAddMemory}
          />
        </Stack>
        <Stack
          borderTop={
            backgroundColor === "moonlight"
              ? "1px solid #FFFFFF"
              : "1px solid #000000"
          }
        ></Stack>

        <Stack
          mt={"36px"}
          overflow={!addMemory && "scroll"}
          sx={{
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {addMemory ? (
            <MemoryToUpload
              handleDrop={handleDrop}
              handleUploadaFileInBebaMemory={handleUploadaFileInBebaMemory}
              backgroundColor={backgroundColor}
              droppedImage={droppedImage}
            />
          ) : (
            <>
              {memoryData?.map((data, i) => (
                <Stack
                  key={i}
                  bgcolor={
                    backgroundColor === "moonlight"
                      ? "rgba(0, 0, 0, 0.25)"
                      : "rgba(255, 255, 255, 0.25)"
                  }
                  border={" 1px solid rgba(0, 0, 0, 0.25)"}
                  borderRadius={"5px"}
                  textAlign={"left"}
                  alignItems={"center"}
                  direction={"row"}
                  gap={"10px"}
                  color={"#fff"}
                  p={"8px 10px"}
                  mb={"15px"}
                >
                  <img
                    width={"25px"}
                    src={`${
                      data?.fileType === ""
                        ? docu
                        : data?.fileType === "application/pdf"
                        ? pdf
                        : data?.fileType === "application/vnd.ms-excel" ||
                          "application/msword" ||
                          "application/vnd.ms-excel" ||
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                        ? docu
                        : ""
                    }`}
                    height={"25px"}
                    alt="ico"
                  />
                  <Typography
                    sx={{
                      font: "normal normal normal 15px/17px Averia Serif Libre",
                    }}
                  >
                    {data?.originalName}
                  </Typography>
                </Stack>
              ))}
            </>
          )}
          <Typography onClick={handleLoadMore}>Load More</Typography>
        </Stack>

        <Stack
          direction={"row"}
          mt={"30px"}
          mb={"10px"}
          justifyContent={"space-between"}
        >
          <Typography
            textAlign={"justify"}
            sx={{
              font: "normal normal normal 20px/25px Averia Serif Libre",
            }}
          >
            All Intelligent Connections
          </Typography>
          <Button
            sx={{ p: "0px", textTransform: "none", gap: "5px" }}
            // onClick={handleAddMemory}
          >
            <img src={addico} alt="icon" width={"20px"} height={"20px"} />
            <Typography
              sx={{
                font: "normal normal normal 15px/17px Averia Serif Libre",
              }}
              color={backgroundColor === "moonlight" ? "#000000" : "#000000"}
            >
              Add Connections
            </Typography>
          </Button>
        </Stack>
        <Stack
          borderTop={
            backgroundColor === "moonlight"
              ? "1px solid #FFFFFF"
              : "1px solid #000000"
          }
        ></Stack>
        <Stack
          mt={"30px"}
          mb={"10px"}
          direction={"row"}
          gap={"10px 12px"}
          flexWrap={"wrap"}
          overflow={"scroll"}
          sx={{
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {connectData?.map((ele, index) => (
            <Stack
              key={index}
              width={"134px"}
              bgcolor={
                backgroundColor === "moonlight"
                  ? "rgba(0, 0, 0, 0.25)"
                  : "rgba(255, 255, 255, 0.25)"
              }
              border={" 1px solid rgba(0, 0, 0, 0.25)"}
              borderRadius={"5px"}
              textAlign={"left"}
              alignItems={"center"}
              direction={"row"}
              gap={"5px"}
              color={"#fff"}
              p={"8px 2px 8px 10px"}
            >
              <img
                width={"25px"}
                height={"25px"}
                alt="ico"
                src={`${ele?.icon}`}
              />
              <Typography
                sx={{
                  font: "normal normal normal 15px/17px Averia Serif Libre",
                }}
              >
                {ele?.name}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export default Memory;
