import { Stack, Typography, Button } from "@mui/material";
import indx from "../../images/icons8-index-64-2@2x.png";
import addico from "../../images/icons8-add-100@2x.png";
import grp48 from "../../images/Group 4813@2x.png";
import { useEffect, useState } from "react";
import Base_Url from "../../api/base_Url";
import { useDispatch } from "react-redux";
import { getAllMemories } from "../../redux/actions/chatActions/chatActions";
import AddToMemory from "../../common/memoryandconnection/addToMemory";
import MemoryToUpload from "../../common/memoryandconnection/memorytoupload";

const BebaMemory = ({
  popupRef,
  activeBackground,
  setShowDetails,
  handleAddMemory,
  addMemory,
  Id,
  backgroundColor,
}) => {
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = useState(false);
  const [droppedImage, setDroppedImage] = useState(null);
  const [droppedFile, setDroppedFile] = useState(null);
  console.log(droppedFile, "droppedFile");
  console.log(droppedImage, "droppedImage");

  const handleUploadedListInBebaMemory = async () => {
    try {
      const uploadedList = await Base_Url.get(
        `c/getUserCollections/${Id}?page=${1}`
      );
      console.log(uploadedList, "upload");
      if (uploadedList?.status === 200) {
        dispatch(getAllMemories(uploadedList?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleUploadedListInBebaMemory();
  }, []);

  const handleUploadaFileInBebaMemory = async () => {
    console.log(droppedFile, "sdcs");
    if (droppedFile !== null) {
      const formdata = new FormData();
      formdata.append("file", droppedFile);
      formdata.append("uploadType", 1);
      formdata.append("userId", Id);
      try {
        const uploadFile = await Base_Url.post(`c/ucd`, formdata);
        console.log(uploadFile, "upload");
        if (uploadFile.status === 200) {
          handleUploadedListInBebaMemory();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    setDroppedFile(file);
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setDroppedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Stack
      ref={popupRef}
      height={"768px"}
      className="sidede"
      pt={"30px"}
      pl={"30px"}
      pr={"20px"}
      bgcolor={backgroundColor === "moonlight" ? "#716f69" : "#b0bdaa"}
    >
      <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
        <Stack
          width={"40px"}
          height={"35px"}
          bgcolor={"#62866A"}
          borderRadius={"5px"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={(e) => setShowDetails("")}
        >
          <img alt="icon" height={"25px"} width={"25px"} src={indx} />
        </Stack>

        <Typography
          sx={{
            font: "normal normal normal 20px/25px Averia Serif Libre",

            color: backgroundColor === "moonlight" ? "#ffffff" : "",
          }}
        >
          BEBA Memory
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        mt={"30px"}
        mb={"10px"}
        justifyContent={"space-between"}
      >
        <Typography
          textAlign={"justify"}
          sx={{
            font: "normal normal normal 20px/25px Averia Serif Libre",
          }}
          color={backgroundColor === "moonlight" ? "#ffffff" : ""}
        >
          All Memory
        </Typography>
        <AddToMemory
          handleAddMemory={handleAddMemory}
          backgroundColor={backgroundColor}
        />
      </Stack>

      <Stack
        borderTop={
          activeBackground === 2 ? "1px solid #FFFFFF" : "1px solid #000000"
        }
      ></Stack>

      <Typography
        sx={{ font: "italic normal normal 15px/19px Averia Serif Libre" }}
        textAlign={"left"}
        mt={"15px"}
        color={backgroundColor === "moonlight" ? "#ffffff" : ""}
      >
        Items added here are added to the system memory of BEBA can be accessed
        in any of your chats. Use “in chat” memory to contain your data to
        specific chats.
      </Typography>

      {addMemory && (
        <MemoryToUpload
          handleDrop={handleDrop}
          handleUploadaFileInBebaMemory={handleUploadaFileInBebaMemory}
          backgroundColor={backgroundColor}
          droppedImage={droppedImage}
        />
        // <Stack>
        //   <Stack
        //     onDragOver={handleDragOver}
        //     onDragEnter={handleDragEnter}
        //     onDragLeave={handleDragLeave}
        //     onDrop={handleDrop}
        //     style={{
        //       background: isDragging
        //         ? "rgba(255, 255, 255, 0.5)"
        //         : "rgba(255, 255, 255, 0.25)",
        //       border: "2px dashed rgba(0, 0, 0, 0.25)",
        //       borderRadius: "10px",
        //       marginTop: "30px",
        //       alignItems: "center",
        //       gap: "15px",
        //       justifyContent: "center",
        //       padding: "30px",
        //     }}
        //   >
        //     {droppedImage ? (
        //       <img
        //         src={droppedImage}
        //         alt="Dropped Image"
        //         width="172px"
        //         height="190px"
        //       />
        //     ) : (
        //       <>
        //         <img
        //           src={grp48}
        //           width="172px"
        //           height="190px"
        //           draggable="false"
        //         />
        //         <Typography
        //           sx={{
        //             font: "normal normal normal 13px/16px Averia Serif Libre",
        //           }}
        //           width="172px"
        //           color={backgroundColor === "moonlight" ? "#fff" : ""}
        //         >
        //           Drag and Drop items here to upload them
        //         </Typography>
        //       </>
        //     )}
        //   </Stack>
        //   <Stack marginTop="19px" alignItems="end">
        //     <Button
        //       sx={{
        //         padding: "4px 10px",
        //         font: "normal normal normal 16px/20px Averia Serif Libre",
        //         color: "#ffffff",
        //         background: "#62866A",
        //         "&:hover": {
        //           background: "#62866A",
        //         },
        //       }}
        //       onClick={handleUploadaFileInBebaMemory}
        //     >
        //       Upload Now
        //     </Button>
        //   </Stack>
        // </Stack>
      )}
    </Stack>
  );
};

export default BebaMemory;
