import { Button, Stack, Typography } from "@mui/material";
import SideBarOption from "../../components/sidebarOption/sideBarOption";
import community from "../../images/documenticon/icons8-community-80@2x.png";
import gloves from "../../images/documenticon/icons8-gloves-100@2x.png";
import help from "../../images/documenticon/icons8-help-100@2x.png";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { activeDocumentation } from "../../redux/actions/authActions/authActions";
import SideBar from "../../components/sidebar/sideBar";
import RightSideBar from "../../components/rightSideBar/rightSideBar";

const BebaDocumentation = () => {
  const dispatch = useDispatch();

  const { activeTab } = useSelector((state) => state?.authReducer);

  const handleActiveDocu = () => {
    dispatch(activeDocumentation("document"));
  };
  const handleActiveApiRef = () => {
    dispatch(activeDocumentation("apiref"));
  };
  return (
    <Stack direction={"row"} bgcolor={"#262222"} color={"#fff"}
    //  height={"768px"}
     >
      <Stack className="sideOptions">
        <SideBarOption />
      </Stack>
      <Stack width={"100%"}>
        <Stack
          className="rightsec"
          pr={"20px"}
          pl={"15px"}
          minHeight={"60px"}
          borderBottom={"1px solid #fff"}
          direction={"row"}
        >
          <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
            <Button
              className="f_16"
              sx={{
                textTransform: "none",
                borderBottom: activeTab === "document" && "1px solid #2E3033",
              }}
              onClick={handleActiveDocu}
            >
              Documentation
            </Button>
            <Stack className="point"></Stack>
            <Button
              className="f_16"
              sx={{ textTransform: "none"
            ,borderBottom: activeTab === "apiref" && "1px solid #2E3033", }}
              onClick={handleActiveApiRef}
            >
              API Reference
            </Button>
          </Stack>
          <Stack direction={"row"} gap={"20px"}>
            <Stack direction={"row"} gap={"5px"} className="cur_po">
              <img src={community} alt="icon" height={"20px"} width={"20px"} />
              <Typography className="f_18">Community</Typography>
            </Stack>
            <Stack direction={"row"} gap={"5px"} className="cur_po">
              <img src={gloves} alt="icon" height={"20px"} width={"20px"} />
              <Typography className="f_18">White Glove Service</Typography>
            </Stack>
            <Stack direction={"row"} gap={"5px"} className="cur_po">
              <img src={help} alt="icon" height={"20px"} width={"20px"} />
              <Typography className="f_18">Help</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={"row"}>
          <SideBar/>
          <RightSideBar/>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BebaDocumentation;
