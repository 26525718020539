import { useRef, useEffect } from "react";
import attach from "../../images/icons8-attachment-100@2x.png";
import Base_Url from "../../api/base_Url";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const FileUpload = ({ setSelectedFile, setOcrImage }) => {
  const fileInputRef = useRef(null);
  const { folderId, chatId } = useParams();
  const handleAttachClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    // Call handleOCRImageClick when a new file is selected
    handleOCRImageClick(file);
  };

  const handleOCRImageClick = async (file) => {
    try {
      const formdata = new FormData();
      // Append the selected file to the FormData
      formdata.append("file", file);

      // Send the FormData to the OCR service
      const response = await Base_Url.post("m/om", formdata);

      console.log("🚀 ----------------------------------------------🚀");
      console.log(
        "🚀 ~ handleOCRImageClick ~ response:",
        response?.data?.ocrResponse?.task_id
      );
      console.log("🚀 ----------------------------------------------🚀");

      if (response?.status === 200 && response?.data?.ocrResponse?.task_id) {
        const taskId = response?.data?.ocrResponse?.task_id;
        setTimeout(() => fetchLingoResponse(taskId), 5000);
      }

      // Extract the OCR text from the response and set it in state

      // setOcrImage(ocrText);
    } catch (err) {
      console.log("Something went wrong");
      toast.error(err.message);
    }
  };

  const fetchLingoResponse = async (taskId) => {
    try {
      const response = await Base_Url.get(`m/opm/${taskId}`, {
        responseType: "arraybuffer",
      });
      if (response.status === 200) {
        console.log(response, "response");
      } else if (response.status === 202) {
        // Assuming 202 means processing
        console.log("Processing... will retry in 3 seconds");
        setTimeout(() => fetchLingoResponse(taskId), 3000); // Retry after 5 seconds
      } else {
        setSelectedFile(null);
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching audio:", error);
      setSelectedFile(null);
    }
  };

  return (
    <>
      <img
        className="cur_po"
        alt="icon"
        onClick={() => handleAttachClick()}
        src={attach}
        width={"25px"}
        height={"25px"}
      />
      <input
        type="file"
        accept="*"
        style={{ display: "none" }}
        onChange={handleFileSelect}
        ref={fileInputRef}
      />
    </>
  );
};

export default FileUpload;
