import React from "react";

function Tri({ width = "27", color = "#00767B", height = "14" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className="si-glyph si-glyph-triangle-down"
      viewBox="0 -0.5 17 17"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M10.106 12.69a1.49 1.49 0 01-2.104 0L1.561 6.246c-.582-.581-.839-2.103 1-2.103h12.988c1.901 0 1.582 1.521 1 2.103l-6.443 6.444z"
        className="si-glyph-fill"
      ></path>
    </svg>
  );
}

export default Tri;
