import { Button, Link, Stack, TextField, Typography } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import gogle from "../../images/Group 5@2x.png";
import { useEffect, useState } from "react";
import LeftSide from "../../common/leftside/leftSide";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loggedData,
  userNameAndEmail,
} from "../../redux/actions/authActions/authActions";
import axios from "axios";
import Base_Url from "../../api/base_Url";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialState = {
    userName: "",
    password: "",
  };

  const [userDetails, setUserDetails] = useState(initialState);
  const [locationData, setLocationData] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const getUserLocation = async () => {
    const response = await axios.get("https://ipapi.co/json/");
    setLocationData(response?.data);
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (userName && password !== "") {
        dispatch(userNameAndEmail(userName));
        const data = {
          username: userName,
          password: password,
          Usercity: locationData?.city,
          Usercountry_name: locationData?.country_name,
          Usertimezone: locationData?.timezone,
          Userip: locationData?.ip,
        };
        const res = await Base_Url.post(`a/lSyncBos`, data);
        if (res?.status === 200) {
          localStorage.setItem("token", res?.data?.UserData?.token);
          sessionStorage.setItem("name", res?.data?.UserData?.name);
          dispatch(loggedData(res?.data?.UserData));
          toast.success("Successfully Login");
          setTimeout(() => {
            navigate("/chat");
          }, 1100);
        }
      }
    } catch (error) {
      toast.error("Some-thing went wrong");
    }
  };


  const { userName, password } = userDetails;

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Stack direction={"row"} height={"768px"}>
        <LeftSide />
        <Stack
          width={"63.39%"}
          className="left_box"
          bgcolor={"rgba(98, 134, 106, 0.6)"}
          alignItems={"center"}
        >
          <Stack width={"500px"}>
            <Stack mt={"100px"} gap={"10px"}>
              <Typography
                sx={{
                  font: "normal normal normal 60px/73px Averia Serif Libre",
                  color: "#ffffff",
                }}
              >
                BEBA
              </Typography>
              <Typography
                sx={{
                  font: "normal normal normal 30px/37px Averia Serif Libre",
                  color: "#ffffff",
                }}
              >
                The first privacy first AI system
              </Typography>
            </Stack>

            <Stack
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: "30px" }}
              width={"500px"}
            >
              <Stack>
                <Typography
                  sx={{
                    font: "normal normal normal 15px/17px Averia Serif Libre",
                    color: "#fff",
                  }}
                  textAlign={"justify"}
                >
                  Username
                </Typography>
                <TextField
                  margin="normal"
                  required
                  id="userName"
                  value={userName}
                  placeholder="userName"
                  onChange={handleChange}
                  name="userName"
                  autoFocus
                  sx={{ mt: "9px", background: "#e8f0fe", borderRadius: "5px" }}
                />
              </Stack>
              <Stack mt={"20px"}>
                <Typography
                  sx={{
                    font: "normal normal normal 15px/17px Averia Serif Libre",
                    color: "#fff",
                  }}
                  textAlign={"justify"}
                >
                  Password
                </Typography>

                <TextField
                  margin="normal"
                  sx={{ mt: "9px", background: "#e8f0fe", borderRadius: "5px" }}
                  required
                  name="password"
                  value={password}
                  onChange={handleChange}
                  placeholder="password"
                  type="password"
                  id="password"
                />
              </Stack>
              <Stack mt={"20px"}>
                <Link
                  href="/forgetpsw"
                  sx={{
                    font: " normal normal normal 15px/17px Averia Serif Libre",
                    color: "#fff",
                    textDecoration: "underline",
                  }}
                  textAlign={"end"}
                >
                  Forgot Password?
                </Link>
              </Stack>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: "27px",
                  p: "11px 0px",
                  font: "normal normal normal 20px/25px Averia Serif Libre",
                  border: "2px solid #F2F5F8",
                  borderRadius: "5px",
                  background: "#000",
                  "&:hover": {
                    background: "#000",
                  },
                }}
              >
                Sign in
              </Button>
            </Stack>
            <Typography mt={"30px"} mb={"20px"}>
              {" "}
              <Link
                sx={{
                  textDecoration: "none",
                  color: "#fff",
                  font: "normal normal normal 15px/17px Averia Serif Libre",
                }}
              >
                Are you new?{" "}
                <Link
                  sx={{ color: "#fff", textDecoration: "underline" }}
                  href="/register"
                >
                  Create an Account
                </Link>
              </Link>
            </Typography>
            <Stack direction={"row"} gap={"30px"} height={"40px"}>
              <Stack
                width={"200px"}
                sx={{ borderBottom: "2px solid #fff" }}
                height={"11px"}
              ></Stack>
              <Typography
                sx={{
                  font: "normal normal normal 15px/17px Averia Serif Libre",
                  color: "#fff",
                }}
              >
                Or
              </Typography>
              <Stack
                height={"11px"}
                sx={{ borderBottom: "2px solid #fff" }}
                width={"200px"}
              ></Stack>
            </Stack>
            <Stack
              sx={{
                border: "2px solid #F2F5F8",
                borderRadius: "5px",
                "&:hover": {
                  background: "#53535378",
                  border: "2px solid #53535378",
                  transition: "0.3s",
                },
              }}
              direction={"row"}
              p={"13px 0px 12px 30px"}
              gap={"105px"}
              className="cur_po"

              // width={"465px"}
            >
              <img src={gogle} alt="icon" height={"25px"} width={"25px"} />
              <Typography
                sx={{
                  font: "normal normal normal 20px/25px Averia Serif Libre",
                  color: "#fff",
                }}
              >
                Sign in with Google
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Login;
