import { Button, Typography } from "@mui/material";
import addico from "../../images/icons8-add-100@2x.png";

const AddToMemory = ({ backgroundColor,handleAddMemory }) => {
  return (
    <Button
      sx={{ p: "0px", textTransform: "none", gap: "5px" }}
      onClick={handleAddMemory}
    >
      <img src={addico} alt="icon" width={"20px"} height={"20px"} />
      <Typography
        sx={{
          font: "normal normal normal 15px/17px Averia Serif Libre",
        }}
        color={backgroundColor === "moonlight" ? "#ffffff" : "#000000"}
      >
        Add to Memory
      </Typography>
    </Button>
  );
};

export default AddToMemory;
