import { Stack, Typography } from "@mui/material";
import btlogo from "../../images/Screenshot 2024-04-03M@2x.png";

const BottomLogo = ({ margintop ,mb}) => {
  return (
    <Stack 
    // position={"fixed"}
    // bottom={0}
    >
      <Stack
        direction={"row"}
        justifyContent={"center"}
        mt={margintop}
        marginBottom={mb}
        gap={"10px"}
      >
        <img alt="icon" src={btlogo} height={"40px"} width={"40px"} />
        <Typography
          sx={{
            font: "normal normal normal 30px/37px Averia Serif Libre",
            color: "#ffffff",
          }}
        >
          Bearish FH
        </Typography>
      </Stack>
    </Stack>
  );
};

export default BottomLogo;
