import { Stack, Typography, Link } from "@mui/material";
import belogo from "../../images/Bearish OS Logo_Orange@2x.png";
import info from "../../images/icons8-about-100-2@2x.png";

const Details = ({
  initialData,
  activeBackground,
  popupRef,
  setShowDetails,
  backgroundColor
}) => {
  
  return (
    <Stack
      height={"768px"}
      className="sidede"
      pt={"30px"}
      pl={"30px"}
      pr={"20px"}
      bgcolor={backgroundColor === "moonlight" ? "#716f69" : "#b0bdaa"}
      ref={popupRef}
    >
      <Stack direction={"row"} gap={"54px"}>
        <Stack
          width={"45px"}
          height={"35px"}
          bgcolor={"rgba(242, 245, 248, 0.3)"}
          borderRadius={"5px"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={(e) => setShowDetails("")}
        >
          <img alt="icon" height={"25px"} width={"25px"} src={info} />
        </Stack>
        <Stack direction="row">
          <img alt="icon" src={belogo} width={"50px"} height={"51px"} />
          <Typography
            sx={{
              font: "normal normal normal 40px/49px Averia Serif Libre",
              color: "#ffffff",
            }}
          >
            BEBA
          </Typography>
        </Stack>
      </Stack>
      <Typography
        mt={"33px"}
        mb={"51px"}
        sx={{
          font: "normal normal normal 18px/22px Averia Serif Libre",
        }}
        color="#fff"
      >
        Discover a new era of artificial intelligence with BEBA AI, designed
        with your privacy and well-being at its core. Our advanced, secure, and
        intuitive AI assistant is ready to revolutionize the way you work and
        live.
      </Typography>

      <Stack
        height={"368px"}
        overflow={"scroll"}
        sx={{
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >

        {initialData?.map((ele, i) => (
          <Stack
            color={"#fff"}
            direction={"row"}
            mb={"5px"}
            key={i}
            pr={"25px"}
          >
            <Typography
              sx={{
                font: "normal normal normal 18px/35px Averia Serif Libre",
              }}
            >
              {i + 1}.
            </Typography>
            <Typography
              textAlign={"justify"}
              // letterSpacing={"-1px"}
              sx={{
                font: "normal normal normal 18px/35px Averia Serif Libre",
              }}
            >
              {ele.content}
            </Typography>
          </Stack>
        ))}

      </Stack>

      <Link
        mt={"79px"}
        mb={"19px"}
        sx={{
          font: "normal normal normal 18px/22px Averia Serif Libre",
          color: "#fff",
          textDecoration: "underline",
        }}
      >
        Learn More & Read our Research Papers
      </Link>
    </Stack>
  );
};

export default Details;
