import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import upload from "../../images/icons8-index-64-2@2x.png";
// Assuming you have the upload icon

const DragAndDropUpload = ({ files }) => {


  return (
    <>
      <Stack
        borderRadius={"5px"}
        border={"2px solid rgba(13, 134, 255, 0.4)"}
        bgcolor={"rgba(13, 134, 255, 0.4)"}
        width={"225px"}
        alignItems={"flex-start"}
        direction={"row"}
        gap={"10px"}
        p={"9px 25px"}
        pr={"0px"}
        className="cur_po"
       
      >
        <img alt="icon" src={upload} height={"25px"} width={"25px"} />
        <Typography
          color={"#fff"}
          sx={{ font: " normal normal normal 20px/25px Averia Serif Libre" }}
        >
          {files.length > 0
            ? `Uploaded Input  x${files.length}`
            : "Uploaded Input"}
        </Typography>
      </Stack>
    </>
  );
};

export default DragAndDropUpload;
