import { Stack, Typography } from "@mui/material";

const StartedData = ({ data, i }) => {
  console.log(data, "data");
  return (
    <Stack>
      <Stack
        key={i}
        width={"250px"}
        height={"125px"}
        sx={{
          backgroundImage: `url('${data?.backgrond}')`,
          backgroundSize: "100% 100%",
        }}
        pl={"10px"}
        textAlign={"left"}
        justifyContent={"end"}
        border={"2px solid #2E3033"}
        borderRadius="5px"
      >
        <Typography className="fo_15_norm">
         {data?.heading}
        </Typography>
        <Typography pb={"10px"} className="f_12">
       {data?.content}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default StartedData;
