import {
  SETUSERNAME_EMAIL,
  SET_BACKGROUND_COLOR,
  SET_DOCUMENTATION,
  SET_FOLDER_NAME,
  SET_LOGGED_DATA,
  SET_ROUTES,
} from "./actiontypes";

const initialState = {
  loginMessage: "",
  userNameOrEmail: "",
  foldername: "",
  backgroundColor: "",
  loggedData: null,
  activeTab:"",
  activeRoute:""
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETUSERNAME_EMAIL:
      return {
        ...state,
        userNameOrEmail: action.payload,
      };

    case SET_FOLDER_NAME:
      return {
        ...state,
        foldername: action.payload,
      };

    case SET_BACKGROUND_COLOR:
      return {
        ...state,
        backgroundColor: action.payload,
      };

    case SET_LOGGED_DATA:
      return {
        ...state,
        loggedData: action.payload,
      };

    case SET_DOCUMENTATION:
      return {
        ...state,
        activeTab: action.payload,
      };

    case SET_ROUTES:
      return {
        ...state,
        activeRoute: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
