import { Button, Stack, Typography } from "@mui/material";
import FileUpload from "../../components/fileUpload/fileUpload";
import share from "../../images/icons8-send-100@2x.png";
import {
  autoChatData,
  chatData,
  detectFileType,
  detectLanguage,
  forceLoad,
  loader,
  newSessionChat,
  newSetup,
} from "../../redux/actions/chatActions/chatActions";
import { Form, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Base_Url from "../../api/base_Url";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
const ChatInput = ({ bgcolr, from, folderIds, chatIds }) => {
  const { loggedData } = useSelector((state) => state.authReducer);
  const textareaRef = useRef(null);
  const stackRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [textareaValue, setTextareaValue] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [ocrImage, setOcrImage] = useState("");

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  useEffect(() => {
    adjustStackHeight();
  }, [textareaValue]);

  const adjustStackHeight = () => {
    if (textareaRef.current && stackRef.current) {
      const textareaHeight = textareaRef.current.scrollHeight;
      if (textareaValue) {
        stackRef.current.style.height = `${textareaHeight}px`;
      } else {
        stackRef.current.style.height = "35px";
      }
    }
  };

  const handleChat = async () => {
    const payload = {
      text: textareaValue,
    };

    if (textareaValue !== "") {
      // for detecting language
      const res = await Base_Url.post(`di/dtl`, payload);
      if (res.status === 200) {
        dispatch(loader(true));
        dispatch(detectLanguage(res?.data));
        dispatch(chatData(textareaValue));

        if (res?.data?.language !== "english") {
          try {
            const translatePayload = {
              text: textareaValue,
              targetLang: "english",
              sourceLang: res?.data?.language,
            };
            // for translation
            dispatch(chatData(textareaValue));

            dispatch(loader(true));

            setTextareaValue("");
            const translated = await Base_Url.post("di/tt", translatePayload);
            if (translated?.status === 200) {
              const prefixToRemove = "Answer for: ";
              const modifiedAnswer = translated?.data?.translate?.replace(
                prefixToRemove,
                ""
              );
              const formdata = new FormData();
              // Check if orcImage value isn't empty
              if (ocrImage) {
                // Combine "Image Data", orcImage, and modifiedAnswer
                const imageDataWithText = `Image Data: ${ocrImage} ${modifiedAnswer}`;

                // Append the combined string to FormData
                formdata.append("messages", imageDataWithText);
              } else {
                // If orcImage is empty, only append modifiedAnswer
                formdata.append("messages", modifiedAnswer);
              }

              formdata.append("useContext", false);
              formdata.append("userId", loggedData?.user);
              if (from === "auto") {
                formdata.append("folderId", folderIds);
                formdata.append("chatId", chatIds);
              }

              try {
                // for converse AI
                const autoChat = await Base_Url.post("c/cnes", formdata);
                if (autoChat?.status === 200) {
                  setTextareaValue("");
                  const folderId = folderIds || autoChat?.data?.folderId;
                  const chatId = chatIds || autoChat?.data?.chatId;
                  console.log(autoChat, "1234");
                  const chatpayload = {
                    text: autoChat?.data?.aiResponse,
                    targetLang: res?.data?.language,
                    sourceLang: "english",
                  };
                  // for translation
                  const convertChatData = await Base_Url.post(
                    "di/tt",
                    chatpayload
                  );
                  console.log(convertChatData, "convertChatData");
                  if (convertChatData?.status === 200) {
                    const chatResponse = {
                      userQuestion: textareaValue,
                      autoAnswer: convertChatData?.data?.translate,
                    };
                    dispatch(loader(false));
                    dispatch(autoChatData(chatResponse));
                    if (from !== "auto") {
                      const route = `/inchat/${folderId}/${chatId}`;
                      navigate(route);
                    } else if (from === "auto") {
                      dispatch(forceLoad(true));
                    }
                  }
                }
              } catch (error) {
                console.log(error, "error");
              }
              dispatch(chatData(textareaValue));
              // navigate("/inchat");
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          dispatch(chatData(textareaValue));
          if (from === "auto") {
            const data = {};
            dispatch(newSetup("inchat"));
          } else {
            dispatch(newSetup("main"));
          }
          dispatch(loader(true));
          setTextareaValue("");

          navigate("/inchat");
          const formdata = new FormData();
          formdata.append("messages", textareaValue);
          formdata.append("useContext", false);
          formdata.append("userId", loggedData?.user);
          if (from === "auto") {
            formdata.append("folderId", folderIds);
            formdata.append("chatId", chatIds);
          }
          try {
            // for converse AI
            const autoChat = await Base_Url.post("c/cnes", formdata);
            if (autoChat?.status === 200) {
              setTextareaValue("");
              const folderId = autoChat?.data?.folderId;
              const chatId = autoChat?.data?.chatId;
              const chatResponse = {
                userQuestion: textareaValue,
                autoAnswer: autoChat?.data?.aiResponse,
              };
              dispatch(loader(false));

              if (from === "auto") {
                dispatch(newSessionChat(chatResponse));
              } else {
                dispatch(autoChatData(chatResponse));
              }
              // const route = `/inchat/${folderId}/${chatId}`;
              // const route = `/inchat`;
              // navigate(route);
            }
          } catch (error) {
            console.log(error, "error");
          }
        }
      }
    } else {
      const formData = new FormData();
      formData.append("file", selectedFile);
      if (selectedFile !== null) {
        // for detecting file type
        const secondRes = await Base_Url.post(`di/dit`, formData);
        console.log(secondRes, "second res");
        if (secondRes.status === 200) {
          if (secondRes?.data?.type === "image") {
            // for image ocr
            const handleImageupload = await Base_Url.post(`m/om`, formData);
            if (handleImageupload?.status === 200) {
              const taskId = handleImageupload?.data?.ocrResponse?.task_id;
              // for ocr status checking
              const checkStatus = await Base_Url.get(`m/opm/${taskId}`);
              if (checkStatus?.status === 200) {
                setSelectedFile(null);
                const folderId = checkStatus?.data?.proccessedImage?.folderId;
                const chatId = checkStatus?.data?.proccessedImage?.chatId;
                const imgResponse = {
                  userQuestion: textareaValue,
                  autoAnswer: checkStatus?.data?.proccessedImage?.aiResponse,
                };
                dispatch(loader(false));
                dispatch(autoChatData(imgResponse));
                const route = `/inchat/${folderId}/${chatId}`;
                navigate(route);
              }
            }
          } else {
            // for handle Audio/video uploads
            const handleAudioVideoUpload = await Base_Url.post(
              `m/avm`,
              formData
            );
            console.log(handleAudioVideoUpload, "handleAudioVideoUpload");
            if (handleAudioVideoUpload?.status === 200) {
              const taskId =
                handleAudioVideoUpload?.data?.transcriptionData?.transcription
                  ?.task_id;

              // for transcription status
              const handleTranscription = await Base_Url.get(
                `m/avpm/${taskId}`
              );
              setSelectedFile(null);
              console.log(handleTranscription, "handle transcription");
            }
          }
          setSelectedFile(null);
          dispatch(detectFileType(secondRes?.data));

          // navigate("/inchat");
        }
      }
    }
  };

  useEffect(() => {
    if (document.activeElement !== textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  return (
    <Stack
      //   width={"875px"}
      sx={{
        background: bgcolr,

        border: "2px solid rgba(242, 245, 248, 0.25)",
        borderRadius: "5px",
        overflow: "hidden",
        transition: "height 0.3s ease",
      }}
      direction={"row"}
      ref={stackRef}
      justifyContent={"space-between"}
      p={"16px 10px 16px 30px"}
    >
      {" "}
      <textarea
        ref={textareaRef}
        className={from === "start" ? "placeholder-white" : "placeholder-blk"}
        style={{
          outline: "none",
          resize: "none",
          width: "614px",
          border: "none",
          color: from === "start" ? "#fff" : "#000",
          background: "none",
          paddingTop: "5px",
          paddingRight: "40px",
          font: "normal normal normal 20px/25px Averia Serif Libre",
          "::placeholder": { color: "#fff" },
        }}
        value={textareaValue}
        onChange={handleTextareaChange}
        placeholder="Hello, I’m BEBA, a privacy first AI, what can I help you with today?"
      />
      <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
        <FileUpload
          setSelectedFile={setSelectedFile}
          setOcrImage={setOcrImage}
        />
        <Button
          sx={{
            background: "#62866A",
            "&:hover": {
              background: "#62866A",
            },
            gap: "7px",
          }}
          onClick={() => handleChat()}
        >
          <Typography
            sx={{
              font: "normal normal normal 20px/20px Averia Serif Libre",
              color: "#fff",
            }}
          >
            Chat Now
          </Typography>
          <img alt="icon" src={share} height={"20px"} width={"20px"} />
        </Button>
      </Stack>
    </Stack>
  );
};

export default ChatInput;
