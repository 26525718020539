import { Button, Stack, Typography } from "@mui/material";

const ModelData = ({ data, i }) => {
  return (
    <Stack>
      <Stack
        key={i}
        width={"250px"}
        height={"125px"}
        sx={{
          backgroundImage: `url('${data?.backgrond}')`,
          backgroundSize: "100% 100%",
        }}
        pl={"10px"}
        textAlign={"left"}
        justifyContent={"center"}
        border={"2px solid #2E3033"}
        gap={"5px"}
        borderRadius="5px"
      >
        <Typography className="fo_15_norm" >{data?.heading}</Typography>
        <Typography pb={"10px"} minHeight={"44px"} className="f_12">
          {data?.content}
        </Typography>
        <Button sx={{ p: "0px", textTransform: "none" ,color:"#000",justifyContent:"flex-start"}}>
          {" "}
          <Typography textAlign={"left"} className="f_12">Start Now</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

export default ModelData;
