import { Stack, Typography, Link } from "@mui/material";
import avtr from "../../images/Screenshot 2024-04-03 at 9.51.41 PM@2x.png";

const LeftSide = () => {
  return (
    <Stack
      width={"36.60%"}
      bgcolor={"rgba(235, 233, 226, 0.5)"}
      className="left_box"
      alignItems={"center"}
    >
      <Stack mt={"140px"}>
        <img src={avtr} width={"386px"} height={"386px"} alt="avt" />
      </Stack>
      <Stack mt={"25px"} gap={"5px"} mb={"125px"}>
        <Typography
          sx={{
            font: "normal normal bold 18px / 16px Averia Serif Libre",
            color: "#62866A",
          }}
        >
          Love the BEBA experience?
        </Typography>
        <Typography
          sx={{
            color: "#62866A",
            font: "normal normal bold 13px / 16px Averia Serif Libre",
          }}
        >
          Supercharge your productivity with{" "}
          <Link
            sx={{
              color: "#62866A !important",
              textDecoration: "underline",
            }}
            className="cur_po"
            href="https://app.bearishos.com/"
          >
            Bearish OS
          </Link>{" "}
          Powered by BEBA
        </Typography>
      </Stack>
      <Typography
        sx={{ font: "normal normal normal 18px/22px Averia Serif Libre" }}
        mb={"25px"}
      >
        Build with BEBA’s Autonomous{" "}
        <Link
          sx={{
            color: "#000000 !important",
            textDecoration: "underline",
          }}
          href="/"
          className="cur_po"
        >
          API
        </Link>
      </Typography>
    </Stack>
  );
};

export default LeftSide;
