import { Stack, Typography, Button } from "@mui/material";
import btlogo from "../../images/Screenshot 2024-04-03M@2x.png";

const AutoNomous = ({
  popupRef,
  backgroundColor,
  setBebaAction,
  bebaAction,
  setBebaDecision,
  bebaDecision,
}) => {
  return (
    <Stack
      ref={popupRef}
      bgcolor={
        backgroundColor === "moonlight"
          ? "rgb(155 155 155 / 51%)"
          : "rgba(255, 255, 255, 0.5)"
      }
      ml={"47px"}
      width={"42.82%"}
      p={"30px 20px 25px 30px"}
      borderLeft={"2px solid hsl(210deg 33% 96% / 20%)"}
      borderTop={"2px solid hsl(210deg 33% 96% / 20%)"}
      borderRadius={"5px 0px 0px 5px"}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
          <img alt="icon" height={"40px"} width={"40px"} src={btlogo} />

          <Typography
            sx={{
              font: "normal normal normal 20px/25px Averia Serif Libre",
            }}
            color={backgroundColor === "moonlight" && "#fff"}
          >
            BEBA Autonomous
          </Typography>
        </Stack>
        <Button
          sx={{
            bgcolor: "rgba(20, 82, 32, 0.7)",
            color: "#fff",
            textTransform: "none",
          }}
          className="
                  fo_15_norm
                  "
        >
          Alpha
        </Button>
      </Stack>
      <Stack mt={"10px"} borderTop={"1px solid #000000"}></Stack>
      <Typography
        mt={"10px"}
        textAlign={"left"}
        color={backgroundColor === "moonlight" && "#fff"}
        sx={{ font: "italic normal normal 15px/19px Averia Serif Libre" }}
      >
        In this mode BEBA can act on your behalf fully or partially, we always
        recommend that you review the actions of BEBA before he executes them he
        can make mistakes, and does get things wrong.
      </Typography>
      <Stack mt={"20px"} textAlign={"left"}>
        <Stack gap={"10px"}>
          <Typography
            sx={{
              font: "normal normal normal 20px/25px Averia Serif Libre",
            }}
            color={backgroundColor === "moonlight" && "#fff"}
          >
            Your Request
          </Typography>
          <Stack
            border={"1px solid rgba(242, 245, 248, 0.5)"}
            borderRadius={"5px"}
            bgcolor={
              backgroundColor === "moonlight"
                ? "#8D8D8D"
                : "rgba(255, 255, 255, 0.5)"
            }
            height={"90px"}
          >
            <Typography
              className="fo_15_norm"
              color={backgroundColor === "moonlight" && "#fff"}
              p={"10px 21px 10px 10px"}
            >
              I’m writing a paper about the future of AI in Universities and I’m
              hoping to help people better understand? Do you have any research
              papers that can help with this?
            </Typography>
          </Stack>
        </Stack>
        <Stack mt={"20px"} gap={"10px"}>
          <Typography
            sx={{
              font: "normal normal normal 20px/25px Averia Serif Libre",
            }}
            color={backgroundColor === "moonlight" && "#fff"}
          >
            BEBA’s Decision
          </Typography>
          <Stack
            border={"1px solid rgba(242, 245, 248, 0.5)"}
            borderRadius={"5px"}
            bgcolor={
              backgroundColor === "moonlight"
                ? "#8D8D8D"
                : "rgba(255, 255, 255, 0.5)"
            }
            height={"90px"}
          >
            <textarea
              className={`textA ${
                backgroundColor === "moonlight"
                  ? "placeholderColorwh"
                  : "placeholderColorbk"
              }`}
              style={{
                resize: "none",
                height: "100px",
                border: "none",
                background: "none",
                outline: "none",
                color: backgroundColor === "moonlight" && "#fff",
                padding: "10px 18px 10px 10px",
              }}
              value={bebaDecision}
              onChange={(e) => setBebaDecision(e.target.value)}
              placeholder="I’m writing a paper about the future of AI in Universities and I’m hoping to help people better understand? Do you have any research papers that can help with this?"
            />
          </Stack>
        </Stack>
        <Stack mt={"20px"} gap={"10px"}>
          <Typography
            sx={{
              font: "normal normal normal 20px/25px Averia Serif Libre",
            }}
            color={backgroundColor === "moonlight" && "#fff"}
          >
            BEBA’s Actions
          </Typography>
          <Stack
            border={"1px solid rgba(242, 245, 248, 0.5)"}
            borderRadius={"5px"}
            height={"165px"}
            bgcolor={
              backgroundColor === "moonlight"
                ? "#8D8D8D"
                : "rgba(255, 255, 255, 0.5)"
            }
          >
            <textarea
              className={`textA ${
                backgroundColor === "moonlight"
                  ? "placeholderColorwh"
                  : "placeholderColorbk"
              }`}
              style={{
                resize: "none",
                height: "165px",
                border: "none",
                background: "none",
                outline: "none",
                color: backgroundColor === "moonlight" && "#fff",
                padding: "10px 18px 10px 10px",
              }}
              value={bebaAction}
              onChange={(e) => setBebaAction(e.target.value)}
              placeholder="I’m writing a paper about the future of AI in Universities and I’m hoping to help people better understand? Do you have any research papers that can help with this?"
            />
          </Stack>
        </Stack>
        <Stack alignItems={"end"} mt={"30px"}>
          <Button
            sx={{
              p: "6px 23px",
              bgcolor: "#B44545",
              font: "normal normal normal 20px/20px Averia Serif Libre",
              textTransform: "none",
              color: "#fff",
            }}
          >
            Stop BEBA
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AutoNomous;
