import { combineReducers } from "redux";
import chatReducer from "../reducers/chatreducers/chatReducer";
import authReducer from "../reducers/authReducer/authReducer";

const rootReducer = combineReducers({
  authReducer: authReducer,
  chatReducer: chatReducer,
});

export default rootReducer;
