import { Stack, Typography, Button } from "@mui/material";
import folder from "../../images/icons8-folder-100-2@2x.png";

const CreateFolder = ({
  popupRef,
  activeBackground,
  setShowDetails,
  backgroundColor,
  folderName,
  setFolderName,
  handleAddFolder,
}) => {
  
  return (
    <Stack
      ref={popupRef}
      height={"768px"}
      className="sidede"
      pt={"30px"}
      pl={"30px"}
      pr={"20px"}
      bgcolor={backgroundColor === "moonlight" ? "#716f69" : "#b0bdaa"}
    >
      <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
        <Stack
          width={"40px"}
          height={"35px"}
          bgcolor={"#62866A"}
          borderRadius={"5px"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={(e) => setShowDetails("")}
        >
          <img alt="icon" height={"25px"} width={"25px"} src={folder} />
        </Stack>

        <Typography
          sx={{
            font: "normal normal normal 20px/25px Averia Serif Libre",

            color: backgroundColor === "moonlight" ? "#ffffff" : "",
          }}
        >
          Create a Folder
        </Typography>
      </Stack>
      <Typography
        mt={"30px"}
        mb={"10px"}
        textAlign={"justify"}
        sx={{ font: "normal normal normal 20px/25px Averia Serif Libre" }}
        color={backgroundColor === "moonlight" ? "#ffffff" : ""}
      >
        BEBA Folders
      </Typography>
      <Stack
        borderTop={
          activeBackground === 2 ? "1px solid #FFFFFF" : "1px solid #000000"
        }
      ></Stack>
      <Typography
        sx={{ font: "italic normal normal 15px/19px Averia Serif Libre" }}
        textAlign={"left"}
        mt={"15px"}
        color={backgroundColor === "moonlight" ? "#ffffff" : ""}
      >
        Folders hold a collection of chats, when you a chat to a folder BEBA
        considers that chat as part of his memory when answering your questions.
      </Typography>
      <Stack
        bgcolor="rgba(255, 255, 255, 0.25)"
        border="1px solid rgba(0, 0, 0, 0.25)"
        borderRadius="10px"
        mt={"20px"}
        direction={"row"}
        justifyContent={"space-between"}
        p={"10px 10px 10px 10px"}
      >
        <input
          placeholder="Folder Name Here"
          className="white-placeholder"
          onChange={(e) => setFolderName(e.target.value)}
          value={folderName}
          style={{
            width: "170px",
            color: "#fff",
            font: "normal normal normal 20px/25px Averia Serif Libre",
            outline: "none",
            border: "none",
            background: "rgba(255, 255, 255, 0.04)",
          }}
        />
        <Button
          sx={{
            width: "124px",
            p: "0px",
            font: "normal normal normal 16px/20px Averia Serif Libre",
            color: "#ffffff",
            background: "#62866A",
            "&:hover": {
              background: "#62866A",
            },
          }}
          onClick={handleAddFolder}
        >
          Create Now
        </Button>
      </Stack>
    </Stack>
  );
};

export default CreateFolder;
