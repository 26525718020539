import { Stack, Typography, Button } from "@mui/material";
import arrow from "../../images/icons8-arrow-100-5@2x.png";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const UserActionsPopup = () => {
  const navigate = useNavigate();

  const { userNameOrEmail, loggedData } = useSelector(
    (state) => state?.authReducer
  );

  const handleLogOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  return (
    <Stack>
      <Stack
        padding="15px 15px 8px"
        gap="15px"
        borderRadius={"0px 0px 5px 5px"}
        // bgcolor={
        //   activeBackground === 2
        //     ? "rgba(0, 0, 0, 0.95)"
        //     : "rgba(98, 134, 106, 0.95)"
        // }
        bgcolor={"rgba(98, 134, 106, 0.95)"}
        color={"#fff !important"}
      >
        <Typography className="fo_15_norm" color={"#fff !important"}>
          {userNameOrEmail}
        </Typography>
        <Typography className="fo_15_norm" color={"#fff !important"}>
          {loggedData?.name}
        </Typography>
      </Stack>
      <Stack gap={"20px"} pt={"12px"} pl={"15px"} pb={"18px"}>
        <Stack direction={"row"} gap={"10px"}>
          <Typography className="fo_15_norm">Subscription Type:</Typography>
          <Typography className="fo_15_norm" color={"#62866A"}>
            Pro
          </Typography>
        </Stack>
        <Button
          sx={{
            p: "0px",
            justifyContent: "flex-start",
            color: "#000",
            textTransform: "unset",
          }}
          className="fo_15_norm"
        >
          <Stack direction={"row"} gap={"30px"}>
            <Typography className="fo_15_norm">
              BEBA’s Policies & Legal
            </Typography>
            <img alt="icon" height={"20px"} width={"20px"} src={arrow} />
          </Stack>
        </Button>
        <Button
          sx={{
            p: "0px",
            justifyContent: "flex-start",
            color: "#000",
            textTransform: "unset",
          }}
          className="fo_15_norm"
        >
          <Stack
            direction={"row"}
            gap={"76px"}
            onClick={() => navigate("/bebadocuments")}
          >
            <Typography className="fo_15_norm">Build with BEBA</Typography>
            <img alt="icon" src={arrow} height={"20px"} width={"20px"} />
          </Stack>
        </Button>
        <Button
          sx={{
            p: "0px",
            justifyContent: "flex-start",
            color: "#000",
            textTransform: "unset",
          }}
          className="fo_15_norm"
        >
          Settings
        </Button>
        <Button
          sx={{
            p: "0px",
            justifyContent: "flex-start",
            color: "#000",
            textTransform: "unset",
          }}
          className="fo_15_norm"
          onClick={handleLogOut}
        >
          Logout
        </Button>
      </Stack>
    </Stack>
  );
};

export default UserActionsPopup;
