export const SET_CHAT_DATA = "SET_CHAT_DATA";
export const SET_INPUT_LANGUAGE = "SET_INPUT_LANGUAGE";
export const DETECT_FILE_TYPE = "DETECT_FILE_TYPE";
export const SET_AUTO_CHAT_DATA = "SET_AUTO_CHAT_DATA";
export const SET_LOADER = "SET_LOADER";
export const GET_ALL_FOLDERS = "GET_ALL_FOLDERS";
export const GET_ALL_CHATS = "GET_ALL_CHATS";
export const GET_ALL_FOLDER_CHATS = "GET_ALL_FOLDER_CHATS";
export const GET_ALL_MEMORIES = "GET_ALL_MEMORIES";
export const NEW_SESSION_OF_CHAT = "NEW_SESSION_OF_CHAT";
export const SETUP = "SETUP";
export const BACK_TO = "BACK_TO";
export const FORCE_LOAD = "FORCE_LOAD";
