import { useNavigate, Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";

const Protected = () => {

  const navigate = useNavigate();

  let userData = localStorage.getItem("token") || "";
  
  useEffect(() => {
    if (userData === null || userData === undefined || userData === "") {
      navigate("/");
    }
  }, []);
  return userData ? <Outlet /> : <Navigate to="/" />;
};
export default Protected;
