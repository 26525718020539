import { Button, Stack, TextField, Typography } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";

import { useState } from "react";
import LeftSide from "../../common/leftside/leftSide";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userNameAndEmail } from "../../redux/actions/authActions/authActions";
import Base_Url from "../../api/base_Url";

const RegisterPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialState = {
    fullName: "",
    email: "",
    password: "",
  };

  const [userDetails, setUserDetails] = useState(initialState);
  const [confirmPsw, setConfirmPsw] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (password === confirmPsw) {
        dispatch(userNameAndEmail(fullName));
        const data = {
          username: email,
          password: password,
          firstName: fullName.split(" ")[0],
          lastName: fullName.split(" ").slice(1).join(" "),
        };
        const res = await Base_Url.post(`a/rSyncBos`, data);
        if (res?.status === 201) {
          toast.success("Successfully Registered");
          setTimeout(() => {
            navigate("/");
          }, 1100);
        }
      } else {
        toast.error(
          "Password confirmation failed. Make sure both passwords match."
        );
      }
    } catch (error) {
      toast.error("Some-thing went wrong");
    }
  };

  const { fullName, password, email } = userDetails;

  return (
    <Stack direction={"row"} height={"768px"}>
      <Toaster position="top-center" reverseOrder={false} />

      <LeftSide />

      <Stack
        width={"63.39%"}
        className="left_box"
        bgcolor={"rgba(98, 134, 106, 0.6)"}
        alignItems={"center"}
      >
        <Stack width={"500px"}>
          <Stack mt={"100px"} gap={"10px"}>
            <Typography
              sx={{
                font: "normal normal normal 60px/73px Averia Serif Libre",
                color: "#ffffff",
              }}
            >
              BEBA
            </Typography>
            <Typography
              sx={{
                font: "normal normal normal 30px/37px Averia Serif Libre",
                color: "#ffffff",
              }}
            >
              The first privacy first AI system
            </Typography>
          </Stack>

          <Stack
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: "30px" }}
            width={"500px"}
          >
            <Stack>
              <Typography
                sx={{
                  font: "normal normal normal 15px/17px Averia Serif Libre",
                  color: "#fff",
                }}
                textAlign={"justify"}
              >
                Email Address (will serve as your Username)
              </Typography>

              <TextField
                required
                type="email"
                id="email"
                value={email}
                onChange={handleChange}
                name="email"
                autoFocus
                sx={{
                  mt: "9px",
                  background: "#e8f0fe",
                  borderRadius: "5px",
                }}
                inputProps={{ style: { padding: " 5px 10px" } }}
              />
            </Stack>

            <Stack mt={"15px"}>
              <Typography
                sx={{
                  font: "normal normal normal 15px/17px Averia Serif Libre",
                  color: "#fff",
                }}
                textAlign={"justify"}
              >
                Full Name
              </Typography>

              <TextField
                sx={{ mt: "9px", background: "#e8f0fe", borderRadius: "5px" }}
                required
                name="fullName"
                value={fullName}
                onChange={handleChange}
                type="text"
                id="fullName"
                inputProps={{ style: { padding: " 5px 10px" } }}
              />
            </Stack>
            <Stack mt={"15px"}>
              <Typography
                sx={{
                  font: "normal normal normal 15px/17px Averia Serif Libre",
                  color: "#fff",
                }}
                textAlign={"justify"}
              >
                Password
              </Typography>

              <TextField
                sx={{ mt: "9px", background: "#e8f0fe", borderRadius: "5px" }}
                required
                name="password"
                value={password}
                onChange={handleChange}
                type="password"
                id="password"
                inputProps={{ style: { padding: " 5px 10px" } }}
              />
            </Stack>
            <Stack mt={"15px"}>
              <Typography
                sx={{
                  font: "normal normal normal 15px/17px Averia Serif Libre",
                  color: "#fff",
                }}
                textAlign={"justify"}
              >
                Password Confirmation
              </Typography>

              <TextField
                sx={{ mt: "9px", background: "#e8f0fe", borderRadius: "5px" }}
                required
                name="confirmpsw"
                value={confirmPsw}
                onChange={(e) => setConfirmPsw(e.target.value)}
                type="password"
                id="confirmpsw"
                inputProps={{ style: { padding: " 5px 10px" } }}
              />
            </Stack>

            <Button
              type="submit"
              variant="contained"
              sx={{
                mt: "27px",
                p: "14px 0px",
                font: "normal normal normal 20px/25px Averia Serif Libre",
                border: "2px solid #F2F5F8",
                borderRadius: "5px",
                background: "#000",
                "&:hover": {
                  background: "#000",
                },
              }}
            >
              Create Account
            </Button>
          </Stack>
          <Typography
            mt={"10px"}
            mb={"20px"}
            color={"#fff"}
            sx={{ font: "italic normal normal 15px/19px Averia Serif Libre" }}
          >
            By creating an account you agree to our Terms of Service & Privacy
            Policy
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RegisterPage;
