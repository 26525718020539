import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Protected from "./protectedRoute";
import Login from "../pages/loginPage/login";
import FirstEnter from "../pages/firstEnter/firstEnter";
import AfterRegister from "../pages/registerPage/afterRegister";
import ForgotPassword from "../pages/forgotPassword/forgotPassword";
import NewChat from "../pages/chatPage/newChat";
import InChat from "../pages/chatPage/inChat";
import RegisterPage from "../pages/registerPage/registerPage";
import RouteList from "./routeList";
import BebaDocumentation from "../pages/bebaDocumentation/bebaDocumentation";
import { useSelector } from "react-redux";

const MainRoute = () => {
  const isAuthenticated = localStorage.getItem("token");
  const { activeRoute } = useSelector((state) => state?.authReducer);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to="/chat" /> : <Login />}
        />
        <Route
          path="/register"
          element={isAuthenticated ? <Navigate to="/chat" /> : <RegisterPage />}
        />

        <Route element={<ForgotPassword />} path="/forgetpsw" />
        <Route element={<Protected />}>
          <Route element={<FirstEnter />} path="/chat" />
          <Route element={<AfterRegister />} path="/afterregister" />
          <Route element={<NewChat />} path="/newchat" />
          <Route element={<InChat />} path="/inchat/:folderId/:chatId" />
          <Route
            element={<InChat />}
            path={
              activeRoute === "chats" ? "/inchat/:folderId/:chatId" : "/inchat"
            }
          />
          <Route element={<BebaDocumentation />} path="/bebadocuments" />
        </Route>
        <Route path="*" element={<Navigate to={"/"} replace />} />

        {/* <Route path="/inchat" element={<Protected Protecting={InChat} />} /> */}

        {/* {RouteList.map((route) => (
        <Route
          key={route.name}
          path={route.path}
          element={
            route.authRequired ? (
              <Protected>
                <route.element />
              </Protected>
            ) : (
              <route.element />
            )
          }
        />
      ))} */}
      </Routes>
    </>
  );
};

export default MainRoute;
