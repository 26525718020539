import { legacy_createStore as createStore, applyMiddleware } from "redux";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {thunk} from "redux-thunk";
import rootReducer from "./rootReducer/rootReducer";

const initialState = {};

const middleware = [thunk];

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  initialState,
  applyMiddleware(...middleware)
);

export const persistor = persistStore(store);



// import { legacy_createStore as createStore, applyMiddleware } from "redux";
// import {thunk} from "redux-thunk";
// import rootReducer from "./rootReducer/rootReducer";
// import { v4 as uuidv4 } from 'uuid';

// const initialState = {};

// const middleware = [thunk];

// const store = createStore(
//   rootReducer,
//   initialState,
//   applyMiddleware(...middleware)
// );

// export default store;
