import { Button, Menu, Stack, Typography } from "@mui/material";

import { useEffect, useRef, useState } from "react";
import belogo from "../../images/Bearish OS Logo_Orange@2x.png";
import share from "../../images/icons8-send-100@2x.png";
import BottomLogo from "../../common/leftside/bottomLogo";
import folder from "../../images/icons8-folder-100-2@2x.png";
import threeDot from "../../images/icons8-three-dots-100@2x.png";
import TopSection from "../../common/leftside/topSection";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addFolderName,
  forCallingRoutes,
} from "../../redux/actions/authActions/authActions";
import Details from "../../components/firstEnter/details";
import CreateFolder from "../../components/firstEnter/createFolder";
import BebaMemory from "../../components/firstEnter/bebaMemory";
import FileUpload from "../../components/fileUpload/fileUpload";
import Base_Url from "../../api/base_Url";
import {
  getAllChats,
  getAllFolderChats,
  getAllFolders,
} from "../../redux/actions/chatActions/chatActions";

const initialData = [
  { id: "1", content: "Your privacy is our top priority" },
  {
    id: "1",
    content:
      "Built entirely in-house, ensuring data security and confidentiality ",
  },
  { id: "1", content: "No reliance on third-party APIs" },
  {
    id: "1",
    content:
      "Proprietary models and algorithms for seamless, intuitive experience ",
  },
  { id: "1", content: "Focus on mental health and well-being" },
  {
    id: "1",
    content:
      "Blend of cutting-edge proprietary technology and open-source innovation",
  },
  { id: "1", content: " Continuously evolving to meet your unique needs" },
];

const FirstEnter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userNameOrEmail, loggedData, foldername, backgroundColor } =
    useSelector((state) => state.authReducer);
  const { allFolders, allChat, allFolderChat } = useSelector(
    (state) => state.chatReducer
  );
  console.log("allFolders,", allFolders);
  const [searchParams] = useSearchParams();
  const myf = searchParams.get("f");

  const popupRef = useRef(null);

  const [activeBackground, setActiveBackground] = useState(1);
  const [folderName, setFolderName] = useState("");
  const [currentMessage, setCurrentMessage] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(null);
  const [showDetails, setShowDetails] = useState("");
  const [addMemory, setAddMemory] = useState(false);
  const [folderChats, setFolderChats] = useState([]);
  const [allChats, setAllChats] = useState(allChat);
  const [userName, setUserName] = useState(sessionStorage.getItem("name"));
  const [selectedFile, setSelectedFile] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [offset, setOffSet] = useState(0);
  const user = loggedData?.name?.split(" ")[0];

  const handleDragStart = (e, chatId) => {
    e.dataTransfer.setData("text/plain", chatId);
  };

  const handleDrop = async (e, folderId) => {
    e.preventDefault();
    const chatId = e.dataTransfer.getData("text/plain");
    const chat = allChat.find((chat) => chat?._id === chatId);
    if (chat) {
      try {
        const payload = {
          folderId: folderId,
          userId: loggedData?.user,
          conversationTitle: chat?.conversationTitle,
        };
        const createChatInFolder = await Base_Url.post(
          `/c/create-chat-in-folder`,
          payload
        );
        console.log(createChatInFolder, "");
      } catch (error) {
        console.log(error);
      }
      setFolderChats((prevChats) => [...prevChats, chat]);
      const filterdChats = allChat?.filter(
        (data, i) => data?._id !== chat?._id
      );
      setAllChats(filterdChats);
    }
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const getCurrentTime = () => {
      const currentHour = new Date().getHours();
      if (currentHour >= 5 && currentHour < 12) {
        setCurrentMessage("Good Morning");
      } else if (currentHour >= 12 && currentHour < 17) {
        setCurrentMessage("Good Afternoon");
      } else {
        setCurrentMessage("Good Evening");
      }
    };

    getCurrentTime();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowDetails("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddFolder = async () => {
    const folderPayload = {
      userId: loggedData?.user,
      folderName: folderName,
    };
    try {
      const respo = await Base_Url.post(`c/createFolder/`, folderPayload);
      if (respo?.status === 201) {
        dispatch(addFolderName(respo?.data?.folder));
        handleGetAllFolders();
      }
    } catch (err) {
      console.log(err);
    }
    setShowDetails("");
  };

  const handleAddMemory = () => {
    setAddMemory(!addMemory);
  };

  const handleRenameFolder = async (id) => {
    const folderPayload = {
      folderId: id,
      newFolderName: "new Folder",
    };
    try {
      // for handle rename folder
      const renameFolder = await Base_Url.post(
        `c/renameFolder/`,
        folderPayload
      );
      console.log(renameFolder, "sdff");
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteFolder = async (id) => {
    try {
      // for delete folder
      const deleteFolder = await Base_Url.get(`c/deleteFolder/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllFolders = async () => {
    try {
      // for get all users chat folders
      const getAllFolder = await Base_Url.get(
        `c//users/${loggedData?.user}/folders?offset=${offset}`
      );
      if (getAllFolder?.status === 200) {
        console.log(getAllFolder, "wert");
        dispatch(getAllFolders(getAllFolder?.data?.folders));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!myf) {
      handleGetAllFolders();
    } else if (myf) {
      handleOpenFolder(myf);
    }
  }, [myf]);

  const handleLoadMore = async () => {
    setOffSet(offset + 1);
    handleGetAllFolders();
  };

  const handleChat = (data) => {
    const folderId = data?._id;
    const chatId = data?.chatId;
    dispatch(forCallingRoutes("chats"));
    navigate(`/inchat/${folderId}/${chatId}`);
  };

  const handleEnterToChat = () => {
    dispatch(forCallingRoutes(""));
    navigate("/newchat");
  };
  const handleloadChatsId = (ids) => {
    dispatch(forCallingRoutes(""));
    navigate(`/chat?f=${ids}`);
  };
  const [openFolder, setOpenFolder] = useState(false);
  const handleOpenFolder = async (data) => {
    try {
      const response = await Base_Url.get(
        `/c/folders/${data}/${loggedData?.user}/chats`
      );
      if (response?.status === 200) {
        dispatch(getAllFolderChats(response?.data?.chats));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Stack direction={"row"}>
      <Stack
        width={showDetails !== "" ? "65%" : "100%"}
        className="box_img"
        p={showDetails !== "" ? "93px 50px 0px 25px" : "43px 50px 0px 25px"}
        bgcolor={backgroundColor === "moonlight" && "rgba(0, 0, 0, 0.7)"}
      >
        {showDetails !== "" ? (
          ""
        ) : (
          <TopSection
            user={user}
            backgroundColor={backgroundColor}
            activeBackground={activeBackground}
            setActiveBackground={setActiveBackground}
            setIsMenuOpen={setIsMenuOpen}
            setShowDetails={setShowDetails}
          />
        )}

        <Menu
          open={isMenuOpen}
          PaperProps={{
            style: {
              width: "133px",
              height: "185px",
            },
          }}
          onClose={() => setIsMenuOpen(null)}
        >
          hello
        </Menu>

        <Stack direction={"row"} gap={"10px"} justifyContent={"center"}>
          <img alt="icon" src={belogo} width={"50px"} height={"51px"} />
          <Typography
            sx={{
              font: "normal normal normal 40px/49px Averia Serif Libre",
              color: "#ffffff",
            }}
          >
            BEBA
          </Typography>
        </Stack>
        <Stack mt={"32px"}>
          <Typography
            sx={{
              font: "normal normal normal 50px/60px Averia Serif Libre",
              color: "#ffffff",
            }}
          >
            {` ${currentMessage}, ${user !== undefined ? user : ""}`}
          </Typography>
        </Stack>
        <Stack justifyContent={"center"} alignItems={"center"} mt={"38px"}>
          <Stack
            width={"875px"}
            sx={{
              // background: activeBackground === 2 ? "#71706b" : "#b0bdaa",
              background: "rgba(255, 255, 255, 0.25)",
              border:
                backgroundColor === "moonlight"
                  ? " 2px transparent"
                  : "2px solid  rgba(242, 245, 248, 0.25)",
              borderRadius: "5px",
            }}
            direction={"row"}
            justifyContent={"space-between"}
            p={"20px 10px 20px 30px"}
          >
            <Typography
              color={"#FFFFFF"}
              pt={"3px"}
              onClick={handleEnterToChat}
              sx={{ font: "normal normal normal 20px/25px Averia Serif Libre" }}
            >
              Hello, I’m BEBA, a privacy first AI, what can I help you with
              today?
            </Typography>
            <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
              <FileUpload setSelectedFile={setSelectedFile} />

              <Button
                sx={{
                  background: "#62866A",
                  "&:hover": {
                    background: "#62866A",
                  },
                  gap: "7px",
                }}
              >
                <Typography
                  sx={{
                    font: "normal normal normal 20px/20px Averia Serif Libre",
                    color: "#fff",
                  }}
                >
                  Chat Now
                </Typography>
                <img alt="icon" src={share} height={"20px"} width={"20px"} />
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          pr={showDetails !== "" ? "0px" : "190px"}
          justifyContent={"end"}
          alignItems={"center"}
          mt={"25px"}
          gap={"10px"}
          mb={"2px"}
        >
          <Button onClick={() => setShowDetails("createfolder")}>
            <Typography
              sx={{
                font: "normal normal normal 15px/21px Averia Serif Libre",
                color: backgroundColor === "moonlight" ? "#fff" : "#000000",
              }}
            >
              Create a New Folder
            </Typography>
          </Button>
          <Stack
            sx={{ borderRadius: "14px", border: "3px solid #fff" }}
          ></Stack>
          <Button onClick={() => setShowDetails("bebaMemory")}>
            <Typography
              sx={{
                font: "normal normal normal 15px/17px Averia Serif Libre",
                color: backgroundColor === "moonlight" ? "#fff" : "#000000",
              }}
            >
              Add to BEBA Memory
            </Typography>
          </Button>
        </Stack>
        <Stack height={"336px"}>
          {allFolders?.length !== 0 && (
            <Stack>
              <Stack
                pt={"12px"}
                direction={"row"}
                gap={"58px"}
                height={"40px"}
                justifyContent={"center"}
              >
                <Stack
                  width={"343px"}
                  sx={{ borderBottom: "2px solid #fff" }}
                  height={"11px"}
                ></Stack>
                <Typography
                  sx={{
                    font: "normal normal normal 20px/25px  Averia Serif Libre",
                    color: "#fff",
                  }}
                  width={"156px"}
                >
                  {myf ? "In Folder" : "Past Folders"}
                </Typography>
                <Stack
                  height={"11px"}
                  sx={{ borderBottom: "2px solid #fff" }}
                  width={"343px"}
                ></Stack>
              </Stack>

              <Stack
                direction={"row"}
                gap={" 10px 25px"}
                height={"270px"}
                overflow={"scroll"}
                sx={{
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
                justifyContent={"center"}
                flexWrap={"wrap"}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: "10px 25px",
                  }}
                >
                  {!myf && (
                    <>
                      {allFolders?.map((folderName, index) => (
                        <Stack
                          key={index}
                          bgcolor=" rgba(255, 255, 255, 0.25)"
                          border="1px solid rgba(0, 0, 0, 0.25)"
                          borderRadius="10px"
                          width={"401px"}
                          direction={"row"}
                          onClick={() => handleloadChatsId(folderName?._id)}
                          p={"18px 15px 18px 30px"}
                          justifyContent={"space-between"}
                          onDrop={(e) => handleDrop(e, folderName?._id)}
                          onDragOver={allowDrop}
                          className="cur_po"
                        >
                          <Stack direction={"row"} gap={"10px"}>
                            <img
                              alt="icon"
                              src={folder}
                              height={"25px"}
                              width={"25px"}
                            />
                            <Typography
                              sx={{
                                font: "normal normal normal 20px/25px Averia Serif Libre",
                              }}
                              color={"#ffffff"}
                            >
                              {folderName?.folderName}
                            </Typography>
                          </Stack>
                          <img
                            alt="icon"
                            height={"25px"}
                            width={"25px"}
                            src={threeDot}
                            className="cur_po"
                            onClick={(e) => setOpenMenu(e.currentTarget)}
                          />
                        </Stack>
                      ))}
                      {allChats?.map((ele, i) => (
                        <div key={i}>
                          <Stack
                            className="cur_po"
                            bgcolor=" rgba(255, 255, 255, 0.25)"
                            border="1px solid rgba(0, 0, 0, 0.25)"
                            borderRadius="10px"
                            width={"401px"}
                            direction={"row"}
                            p={"18px 15px 18px 30px"}
                            justifyContent={"space-between"}
                            // onClick={handleChat}
                            draggable
                            onDragStart={(e) => handleDragStart(e, ele?._id)}
                          >
                            <Typography
                              sx={{
                                font: "normal normal normal 20px/25px Averia Serif Libre",
                              }}
                              color={"#ffffff"}
                            >
                              {ele?.chatName ? ele?.chatName : `Chat ${i + 1}`}
                            </Typography>

                            <img
                              alt="icon"
                              height={"25px"}
                              width={"25px"}
                              src={threeDot}
                            />
                          </Stack>
                        </div>
                      ))}
                    </>
                  )}
                  {myf && (
                    <>
                      {allFolderChat?.map((ele, i) => (
                        <div key={i}>
                          <Stack
                            className="cur_po"
                            bgcolor=" rgba(255, 255, 255, 0.25)"
                            border="1px solid rgba(0, 0, 0, 0.25)"
                            borderRadius="10px"
                            width={"401px"}
                            direction={"row"}
                            p={"18px 15px 18px 30px"}
                            justifyContent={"space-between"}
                            onClick={() => handleChat(ele)}
                          >
                            <Typography
                              sx={{
                                font: "normal normal normal 20px/25px Averia Serif Libre",
                              }}
                              color={"#ffffff"}
                            >
                              {ele?.conversationTitle ? ele?.conversationTitle : `Chat ${i + 1}`}
                            </Typography>

                            <img
                              alt="icon"
                              height={"25px"}
                              width={"25px"}
                              src={threeDot}
                            />
                          </Stack>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </Stack>
            </Stack>
          )}
          <Typography onClick={handleLoadMore}>Load More</Typography>
        </Stack>
        <Menu
          open={openMenu}
          anchorEl={openMenu}
          PaperProps={{
            style: {
              width: "200px",
              height: "200px",
              paddingTop: "0px",
            },
          }}
          onClose={() => setOpenMenu(null)}
        >
          <Stack m={"15px 0px 15px 15px"} gap={"10px"}>
            <Stack
              direction={"row"}
              gap={"10px"}
              onClick={() => handleRenameFolder(foldername?._id)}
              className="cur_po"
            >
              <img src="" alt="icon" />
              <Typography className="textA">Rename</Typography>
            </Stack>
            <Stack
              direction={"row"}
              gap={"10px"}
              onClick={() => handleDeleteFolder(foldername?._id)}
              className="cur_po"
            >
              <img src="" alt="icon" />
              <Typography className="textA">delete</Typography>
            </Stack>
          </Stack>
        </Menu>
        <BottomLogo margintop={"0px"} mb={"15px"} />

        {showDetails === "details" && (
          <div
            style={{
              transition: "opacity 0.3s ease-in-out",
              opacity: showDetails === "details" ? 1 : 0,
              pointerEvents: showDetails === "details" ? "auto" : "none",
            }}
          >
            <Details
              initialData={initialData}
              backgroundColor={backgroundColor}
              activeBackground={activeBackground}
              popupRef={popupRef}
              setShowDetails={setShowDetails}
            />
          </div>
        )}

        {showDetails === "createfolder" && (
          <CreateFolder
            popupRef={popupRef}
            activeBackground={activeBackground}
            setShowDetails={setShowDetails}
            backgroundColor={backgroundColor}
            folderName={folderName}
            setFolderName={setFolderName}
            handleAddFolder={handleAddFolder}
          />
        )}

        {showDetails === "bebaMemory" && (
          <BebaMemory
            popupRef={popupRef}
            activeBackground={activeBackground}
            setShowDetails={setShowDetails}
            handleAddMemory={handleAddMemory}
            addMemory={addMemory}
            backgroundColor={backgroundColor}
            Id={loggedData?.user}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default FirstEnter;
