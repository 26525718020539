import { Button, Stack, TextField, Typography } from "@mui/material";
import LeftSide from "../../common/leftside/leftSide";
import { useState } from "react";
import styled from "@emotion/styled";

const ForgotPassword = () => {

  const [usernameOrEmail, setUserOrEmail] = useState("");

  const handleForgetPassword = () => {};

  return (
    <Stack direction={"row"} height={"768px"}>
      
      <LeftSide />

      <Stack
        width={"63.39%"}
        className="left_box"
        bgcolor={"rgba(98, 134, 106, 0.6)"}
        alignItems={"center"}
      >
        <Stack width={"500px"}>
          <Stack mt={"100px"} gap={"10px"}>
            <Typography
              sx={{
                font: "normal normal normal 60px/73px Averia Serif Libre",
                color: "#ffffff",
              }}
            >
              BEBA
            </Typography>
            <Typography
              sx={{
                font: "normal normal normal 30px/37px Averia Serif Libre",
                color: "#ffffff",
              }}
            >
              The first privacy first AI system
            </Typography>
          </Stack>
          <Stack
            component="form"
            onSubmit={handleForgetPassword}
            noValidate
            sx={{ mt: "30px" }}
            width={"500px"}
          >
            <Stack>
              <Typography
                sx={{
                  font: "normal normal normal 15px/17px Averia Serif Libre",
                  color: "#fff",
                }}
                textAlign={"justify"}
              >
                Username or Email Address
              </Typography>
              <TextField
                required
                id="userOrEmail"
                value={usernameOrEmail}
                onChange={(e) => setUserOrEmail(e.target.value)}
                name="userOrEmail"
                autoFocus
                inputProps={{
                  style: {
                    padding: " 15px 10px",
                    background: "#ffffff",
                    borderRadius: "5px",
                  },
                }}
                sx={{ mt: "9px", background: "#e8f0fe", borderRadius: "5px" }}
              />
            </Stack>

            <Button
              type="submit"
              variant="contained"
              sx={{
                mt: "27px",
                p: "16px 0px 11px",
                font: "normal normal normal 20px/25px Averia Serif Libre",
                border: "2px solid #F2F5F8",
                borderRadius: "5px",
                background: "#000",
                "&:hover": {
                  background: "#000",
                },
              }}
            >
              Send Reset Password Link
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ForgotPassword;
