import "./App.css";
import "./scss/main.scss";
import { BrowserRouter as Router } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MainRoute from "./routes/mainRoutes";
import { Toaster } from "react-hot-toast";

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  return (
    <div className="App">
      <div className="b_img">
      <Toaster position={"top-center"} />
        <Router>
          <MainRoute />
        </Router>
      </div>
    </div>
  );
};

export default App;
